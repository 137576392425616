import { combineReducers } from 'redux';
import marketplace from './marketplace';
import user from './user';
import cart from './cart';
import dataFesta from './dataFesta';
import loading from './loading';


console.log('reducer => ', {
    marketplace,
    user,
    cart,
    dataFesta,
    loading
})

export default combineReducers({
    marketplace,
    user,
    cart,
    dataFesta,
    loading
});
