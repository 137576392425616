import { CartItem } from "../../model/CardItem";

const INITIAL_STATE = {
    cartItems: [],
};



export default function reducer(state=INITIAL_STATE, action) {
    if (action.type === 'SET_CART_ITEM') {
        const itensStorage = [];

        const items = state.cartItems.map(o => o);
        const item = action.product;
        let newItem = null;
        if (item.hasOwnProperty('itens')) {
            newItem = new CartItem(item.CODIGO, item.DESCRICAO, item.VALOR, 1, 'combo', item.itens);
        } else {
            newItem = new CartItem(item.CODIGO, item.DESCRICAO, item.VALOR, 1);
        }
        

        let addNew = true;
    
        items.forEach(cItem => {
          if (cItem.id === newItem.id) {
            cItem.amount ++;
            addNew = false;
          }
        });

        if (addNew) {
            items.push(newItem);
            itensStorage.push(newItem);
            return { ...state, cartItems: items };
        } else {
            itensStorage.push({...itensStorage, newItem});
            return { ...state, cartItems: items };
        }
    }

    if (action.type === 'DECREASE_AMOUNT_VALUE') {
        const items = state.cartItems.map(obj => {
            if(obj.id === action.product.id) {
                if (obj.amount > 0 ) {
                    obj.amount--;
                }
            }

            return obj;
        });

        return {...state, cartItems: items }
    }

    if (action.type === 'INCREASE_AMOUNT_VALUE') {
        const items = state.cartItems.map(obj => {
            if(obj.id === action.product.id) {
                if (obj.amount + 1 <= action.maxAvailable) {
                    obj.amount++;
                }
            }

            return obj;
        });

        return {...state, cartItems: items }
    }


    if (action.type === 'REMOVE_CART_ITEM') {
        const items = state.cartItems.filter(item => item.id !== action.product.id);
        return {...state, cartItems: items }
    }


    if (action.type === 'CLEAR_CART') {
        return { ...state, cartItems: []}
    }


    return state;
}
