import React, { useState } from 'react';
import Product from '../Product/Product';
import Combo from '../Combo/Combo';
import { connect } from 'react-redux';
import FilterResults from 'react-filter-search';


function Products ({products, combos, selected, cartItems , filter}) {
    const [inputSearch, setInputSearch] = useState('');
    const sumTotalItems = (items) => {
        return items.reduce((acc, prev) => {
            acc = acc + prev.amount;
            return acc;
        }, 0);
    };

    return (
        <div className="mt-1 m-0">
            <div className="products m-0 py-2">
                { selected === 'combos' ? (
                    <div className="row justify-content-center m-0">
                        <FilterResults
                            value={filter}
                            data={combos}
                            renderResults={resultCombos => (
                                <div className="row justify-content-center m-0 ">
                                    {
                                        resultCombos.map(combo => (
                                            <Combo combo={combo}  />
                                        ))
                                    }
                                </div>
                            )}
                        />
                    </div>
                ) : (
                    <div className="row justify-content-center m-0 ">
                        <FilterResults
                            value={filter}
                            data={products}
                            renderResults={resultProducts => (
                                <div className="row justify-content-center m-0 ">
                                    {
                                        resultProducts.map(product => (
                                            <Product product={product} />
                                        ))
                                    }
                                </div>
                            )}
                        />
                    </div>
                )}

                <button data-bs-toggle="modal" data-bs-target="#cartModal"
                    style={{ position: 'fixed', bottom: '5%', right:'4%', width: '75px', height:'75px', zIndex: '20', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}
                    className="btn btn-danger rounded-circle">
                    <svg width="1.6em" height="1.6em" viewBox="0 0 16 16" className="bi bi-cart-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                    </svg>
                    <span style={{fontSize: '1.2rem', marginLeft: '5px'}} >{sumTotalItems(cartItems)}</span>
                </button>
            </div>
        </div>
    );
}


export default connect(state => ({ 
    products: state.marketplace.products,
    selected: state.marketplace.selected,
    combos: state.marketplace.combos,
    cartItems: state.cart.cartItems,
}))(Products);