import React, { useEffect, useState } from 'react';
import * as CartActions from '../../store/actions/cart';
import { connect } from 'react-redux';

import './Combo.css';

function Combo({combo, estoque, dispatch }) {
    const [disponivel, setDisponivel] = useState(true);


    const addItemsToCart = (item) => {
        dispatch(CartActions.setCartItem(item));
        creatToast();
    };

    const checkDisponibilidade = () => {
        let disponivel = true;

        combo.itens.forEach(item => {
            const finded = estoque.find(obj => obj.CODIGO === item.COD_PRODUTO);
            if (!finded) {
                disponivel = false;
            }

            if (finded && parseInt(finded.DISPONIVEL) === 0) {
                disponivel = false;
            }
        });

        setDisponivel(disponivel);
    };

    useEffect(() => {
        checkDisponibilidade();
    })

    const errorImage = (e) => {
        e.target.src = window.BASE_HOME + '/img/noImage.png';
    }

    const creatToast = () => {
        const div = document.createElement('div');
        div.id = 'toast-id';
    
        div.innerHTML = `<div class="position-fixed top-0 end-0 p-3" style="z-index: 5">
            <div id="liveToast" style="background-color: #ffff9e" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header" style="background-color: #ffff9e">
                <strong class="me-auto">Item Adicionado</strong>
            </div>
            <div class="toast-body">
                Um item foi adicionado no carrinho de compras!
            </div>
            </div>
        </div>`;

        document.querySelector('body').appendChild(div);

        setTimeout(() => {
            document.querySelector('#toast-id').remove();
        }, 3500);
    }

    const zoomImage = () => { 
        const $btn = document.createElement('button');
        $btn.setAttribute('data-bs-toggle', 'modal');
        $btn.setAttribute('data-bs-target', `#zoom-image-${combo.CODIGO}`);
        document.body.appendChild($btn);
        $btn.click();
        $btn.remove();
    };


    return (
        <div className="card col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 card-combos">
            <img src={`${window.BASE_IMAGES}/C${combo.CODIGO}.jpg`} 
                onError={(e) => errorImage(e)} 
                onClick={(e) => zoomImage() }
                className="card-img-top combo-image mx-auto d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block"
                alt="produto" style={{width: '185px', height: '140px'}}  />
            <img src={`${window.BASE_IMAGES}/C${combo.CODIGO}.jpg`} 
                onError={(e) => errorImage(e)} 
                onClick={(e) => zoomImage() }
                className="d-lg-none combo-image d-xl-block d-xl-none d-xxl-block d-xxl-none"
                alt="produto"  />
            <div class="card-body text-lg-center text-xl-center text-xxl-center">
                <h5 className="card-title">{combo.DESCRICAO}</h5>
                <p>R$ {combo.VALOR}</p>
                {disponivel ? (
                        <p className="card-text text-uppercase text-success">DISPONÍVEL</p>
                    ): (
                        <p className="card-text text-uppercase text-danger">INDISPONÍVEL</p>
                    )}
                <div className="d-lg-flex justify-content-lg-between d-xl-flex justify-content-xl-between d-xxl-flex justify-content-xxl-between">
                    <button className="btn btn-secondary mr-1 d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block"
                        data-bs-toggle="modal" data-bs-target={`#modal-combo-${combo.CODIGO}`}>Detalhes</button>
                    <button className="btn btn-success d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block"
                        disabled={!disponivel}
                        onClick={() => addItemsToCart(combo)}>Adicionar</button>


                    <button className="btn btn-secondary d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none w-auto w-50" 
                        data-bs-toggle="modal" data-bs-target={`#modal-combo-${combo.CODIGO}`}>Detalhes</button>
                    
                    <button className="btn btn-success d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none w-50"
                        disabled={!disponivel}
                        onClick={() => addItemsToCart(combo)}>Adicionar</button>


                    <div class="modal fade" id={`modal-combo-${combo.CODIGO}`} tabindex="-1" aria-labelledby={`modal-combo-${combo.CODIGO}-label`} aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mx-auto text-start" id={`modal-combo-${combo.CODIGO}-label`}>{combo.DESCRICAO}</h5>
                                <button type="button" class="btn-close d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                                        <img src={`${window.BASE_IMAGES}/C${combo.CODIGO}.jpg`}
                                            onError={(e) => errorImage(e)} 
                                            className="card-img-top combo-image mx-auto d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block" 
                                            alt="combo" style={{width: '100%', marginTop: '50%'}}  />
                                        <img src={`${window.BASE_IMAGES}/C${combo.CODIGO}.jpg`}
                                            onError={(e) => errorImage(e)}
                                            className="card-img-top combo-image mx-auto d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none"
                                            alt="combo" />
                                    </div>
                                    <div className="col-sm-8 col-md-12 col-lg-8 col-xl-8 col-xxl-8 text-start">
                                        <p className="fs-5">Produtos do combo</p>
                                        <ul className="list-group">
                                            {combo.itens.map(item => (
                                                <li className="list-group-item text-start">
                                                    {item.QUANTIDADE}x - {item.PRODUTO}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="col-12 mt-3 w-100 text-end">
                                        <p>Por apenas: <span className="text-success fw-bold">R$ {combo.VALOR}</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer text-start">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id={`zoom-image-${combo.CODIGO}`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-body m-0 p-0">
                        <img src={`${window.BASE_IMAGES}/C${combo.CODIGO}.jpg`} 
                            onError={(e) => errorImage(e)}
                            className="w-100 h-auto"
                            alt="produto"  />
                    </div>
                    <div className="modal-footer m-0 p-0">
                        <button type="button" className="btn btn-sm btn-dark" data-bs-dismiss="modal">Fechar</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(state => ({ 
    cartItems: state.cart.cartItems,
    estoque: state.marketplace.products
}))(Combo);


