import { useEffect, useState } from 'react';
import Calendar from './app/components/calendar/Calendar';
import Products from './app/components/products/Products';
import Header from './app/components/header/Header';
import { connect } from 'react-redux';
import './App.css';


function App({dataFesta, loading}) {
  const [toRender, setToRender] = useState('');
  const [inputSearch, setInputSearch] = useState('');

  const renderProduct = (prod) => {
    setToRender(prod);
  };

  return (
  <div className="App">
    <div className="">
        <Header />
        <Calendar renderProductView={(e) => renderProduct(e)} filter={e => setInputSearch(e)} />
        {
          dataFesta.length > 0 ? (
            <Products toRender={toRender} filter={inputSearch} />
          ): (
            <div className="text-center m-0 mt-5">
              <p className="fs-5">Escolha a data da festa</p>
            </div>
          )
        }
    </div>     

    {loading === true ? (
      <div className="loading-screen text-center">
        <img 
          width="50"
          src={window.BASE_HOME + '/img/loading.gif'}
          alt="loading" />
        <p className="fs-2 text-light">Carregando</p>
      </div>
    ): ('')}
  </div>
  );
}

export default connect(state => ({
  dataFesta: state.dataFesta.date,
  loading: state.loading.loading
}))(App);
 