
export class ItensAgenda {

    constructor(codProduto, name, valor, total, codAgenda, data, codClient, quantidade, agecodapp) {
        this.FILIAL = '1';
        this.COD_PRODUTO = codProduto;
        this.DESCRICAO = name;
        this.QUANTIDADE = quantidade;
        this.VALOR = valor;
        this.TOTAL = total;
        this.COD_AGENDA = codAgenda;
        this.DATA_AGENDA = data;
        this.CLIENTE = codClient;
        this.TIPO = 'P';
        this.STATUS = 'R';
        this.AGECODAPP = agecodapp;
    }
}