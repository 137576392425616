const INITIAL_STATE = {
    products: [],
    combos: [],
    selectedsProducts: [],
    selected: 'produtos'
};

export default function reducer(state=INITIAL_STATE, action) {
    if (action.type === 'SET_PRODUCTS') {
        return {...state, products: action.products };
    }

    if (action.type === 'CLEAN_PRODUCTS') {
        return { ...state, products: [] };
    }

    if (action.type === 'SET_COMBOS') {
        return {...state, combos: action.combos };
    }

    if (action.type === 'CLEAN_COMBOS') {
        return { ...state, combos: [] };
    }

    if (action.type === 'SELECT_PRODUCT_LIST') {
        return {...state, selected: action.selected };
    }

    return state;
}
