import React, { useState } from 'react';
import HttpClient from '../../../services/HttpClient';
import { User } from '../../../model/User';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import InputMask from "react-input-mask";
import  { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as LoadingAction from '../../../store/actions/loading';



function Register({ loading, dispatch }) {
    const [userName, setUserName] = useState('');
    const [userCPF_CNPJ, setUserCPF_CNPJ] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userCel, setUserCel] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userCEP, setUserCEP] = useState('');
    const [userBairro, setUserBairro] = useState('');
    const [userLogradouro, setUserLogradouro] = useState('');
    const [userCity, setUserCity] = useState('');
    const [userNumber, setUserNumber] = useState('');
    const [userState, setUserState] = useState('');
    const [userPass, setUserPass] = useState('');
    const [cUserPass, setcUserPass] = useState('');
    const [wrongCEP, setWrongCEP] = useState(false);
    const [wrongCPF_CNPJ, setWrongCPF_CNPJ] = useState(false);
    const [registerModalInfos, setRegisterModalInfos] = useState({});
    const history = useHistory();

    const getCEP = (cep) => {
        setWrongCEP(false);

        if (cep && cep !== '' && cep.trim() !== '' && cep.length === 8) {
            fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(response => response.json())
            .then(data => {
                if (data.erro) {
                    setWrongCEP(true);
                }

                setUserCEP(data.cep);
                setUserBairro(data.bairro);
                setUserCity(data.localidade);
                setUserState(data.uf);
                setUserLogradouro(data.logradouro);
            });
        }
    };

    const createUser = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (validation()) {
            dispatch(LoadingAction.setLoading(true));
            const user = new User(userName, userLogradouro, userBairro, userCEP,
                userCity, userState, userCPF_CNPJ,
                userPhone, userCel, userEmail, userPass, userNumber);
            
            const data = [user];
            HttpClient.put('/InsereClientes', data).then(res => {
                dispatch(LoadingAction.setLoading(false));
                if (res.status === 201) {
                    alertTrigger(
                        'fine', 
                        'CADASTRO REALIZADO COM SUCESSO!',
                        'Você será redirecionado para a tela de LOGIN');
                }
            }, (err) => {
                dispatch(LoadingAction.setLoading(false));
                switch(err.request.response) {
                    case 'CPF/CNPJ Invalido':
                        alertTrigger(
                            'error', 
                            'ERRO AO CADASTRAR',
                            'SEU CPF/CNPJ NÃO É VÁLIDO');
                        setWrongCPF_CNPJ(true);
                        break;
                    case 'Cadastro ja existe':
                        alertTrigger(
                            'error', 
                            'ERRO AO CADASTRAR',
                            'VERIFICAMOS QUE SEU CPF/CNPJ JÁ ESTÁ CADASTRADO NO SISTEMA');
                        break;
                    default:
                        break;
                 }
            });
        }
    }

    const alertTrigger = (status, title, msg) => {
        const button = createButton('#registerModal');
        const buttonMob = createButton('#registerModalMob');
        

        setRegisterModalInfos({
            status,
            title,
            msg,
        });

        button.click();
        button.remove();
        buttonMob.click();
        buttonMob.remove();
    }

    const createButton = (id) => {
        const buttonElem = document.createElement('button');
        buttonElem.type = 'button';
        buttonElem.setAttribute('data-bs-toggle', 'modal');
        buttonElem.setAttribute('data-bs-target', id);
        buttonElem.style.display = 'none';

        document.body.appendChild(buttonElem);

        return buttonElem;
    }

    const redirectToLogin = () => {
        const $body = document.querySelector('body');
        $body.classList.remove('modal-open');
        document.querySelectorAll('.modal-backdrop').forEach(item => item.remove());

        history.push("/login");
    }


    const validation = () => {
        if (userName !== '' && 
            userCPF_CNPJ !== '' && 
            userEmail !== '' && 
            userCel !== '' && 
            userCEP !== '' &&
            userBairro !== '' &&
            userLogradouro !== '' &&
            userCity !== '' &&
            userNumber !== '' &&
            userState !== '' &&
            (userPass !== '' && userPass === cUserPass)) {
                return true;
            }


        return false;
    }


    return (
        <div className="w-100" style={{ height: '100%!important', maxHeight: '100%', overflowY: 'auto'}}>
            <section className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block 
            d-lg-flex justify-content-lg-center d-xl-flex justify-content-xl-center d-xxl-flex 
            justify-content-xxl-center h-100 my-lg-4 my-xl-4 my-xxl-4" >
                <div className="card text-center w-50 h-100">
                    <div className="card-body">
                        <h1 className="mb-5">Cadastro de Cliente</h1>
                        <p className="text-start">(*) Campo Obrigatório</p>
                        <form className="text-start row" onSubmit={createUser}>
                            <div className="mb-3 col-12">
                                <label forHtml="userName" className="form-label">Nome completo*</label>
                                <input type="text" value={userName}
                                    required
                                    onChange={(e) => setUserName(e.target.value)}
                                    className="form-control"
                                    id="userName" aria-describedby="userName" />
                            </div>
                            <div className="mb-3 col-6">
                                <label forHtml="userEmail" className="form-label">Email*</label>
                                <input type="email" value={userEmail}
                                    required
                                    onChange={(e) => setUserEmail(e.target.value)}
                                    className="form-control"
                                    id="userEmail" />
                            </div>
                            <div className="mb-3 col-6">
                                <label forHtml="userCPF_CNPJ" className="form-label">CPF/CNPJ*</label>
                                <CpfCnpj type="text" value={userCPF_CNPJ}
                                    required
                                    onChange={(e) => {
                                        setUserCPF_CNPJ(e.target.value);
                                        setWrongCPF_CNPJ(false);
                                    }}
                                    className="form-control"
                                    id="userCPF_CNPJ" />
                                {wrongCPF_CNPJ ? (
                                <div className="text-danger">
                                    CPF/CNPJ Inválido
                                </div>): ('')}
                            </div>
                            <div className="mb-3 col-6">
                                <label forHtml="userPass" className="form-label">Senha*</label>
                                <input type="text" value={userPass}
                                    required
                                    onChange={(e) => setUserPass(e.target.value)}
                                    className="form-control"
                                    id="userPass" />
                            </div>
                            <div className="mb-3 col-6">
                                <label forHtml="userConfirmPass" className="form-label">Confirmar Senha*</label>
                                <input type="text" 
                                    value={cUserPass}
                                    required
                                    onChange={(e) => setcUserPass(e.target.value)}
                                    className="form-control"
                                    id="userConfirmPass" />
                                {userPass !== '' && userPass !== cUserPass ? (
                                    <div className="text-danger">
                                        As senhas não são iguais! 
                                    </div>): ('')}
                            </div>
                            <div className="mb-3 col-6">
                                <label forHtml="userCel" className="form-label">Celular*</label>
                                <InputMask value={userCel}
                                    required
                                    onChange={(e) => setUserCel(e.target.value)}
                                    className="form-control"
                                    mask={"(99)99999-9999"}
                                    id="userCel" />
                            </div>
                            <div className="mb-3 col-6">
                                <label forHtml="userPhone" className="form-label">Telefone</label>
                                <InputMask value={userPhone}
                                    onChange={(e) => setUserPhone(e.target.value)}
                                    className="form-control"
                                    mask={"(99)99999-9999"}
                                    id="userPhone" />
                            </div>
                            <div className="mb-3 col-12" style={{width: '30%'}}>
                                <label forHtml="userCep" className="form-label">CEP* (apenas números)</label>
                                <input type="text"
                                    required
                                    max="8"
                                    maxLength="8"
                                    onBlur={(e) => getCEP(e.target.value)}
                                    className="form-control"
                                    id="userCep" />
                                {wrongCEP ? (
                                    <div className="text-danger">
                                        CEP Incorreto!
                                    </div>): ('')}
                            </div>
                            <div className="mb-3 col-10">
                                <label forHtml="userAddress" className="form-label">Endereço*</label>
                                <input type="text"
                                    required
                                    value={userLogradouro}
                                    onChange={(e) => setUserLogradouro(e.target.value)}
                                    className="form-control"
                                    id="userAddress" />
                            </div>
                            <div className="mb-3 col-2">
                                <label forHtml="userNumber" className="form-label">Número*</label>
                                <input type="text"
                                    required
                                    value={userNumber}
                                    onChange={(e) => setUserNumber(e.target.value)}
                                    className="form-control"
                                    id="userNumber" />
                            </div>
                            <div className="mb-3 col-5">
                                <label forHtml="userDistrict" className="form-label">Bairro*</label>
                                <input type="text"
                                    required
                                    value={userBairro}
                                    onChange={(e) => setUserBairro(e.target.value)}
                                    className="form-control"
                                    id="userDistrict" />
                            </div>
                            <div className="mb-3 col-5">
                                <label forHtml="userCity" className="form-label">Cidade*</label>
                                <input type="text" value={userCity}
                                    required
                                    onChange={(e) => setUserCity(e.target.value)}
                                    className="form-control"
                                    id="userCity" />
                            </div>
                            <div className="mb-3 col-2">
                                <label forHtml="userState" className="form-label">Estado*</label>
                                <input type="text" value={userState}
                                    required
                                    onChange={(e) => setUserState(e.target.value)}
                                    className="form-control"
                                    id="userState" />
                            </div>
                            <button type="submit"
                                disabled={!validation()}
                                className="btn btn-dark text-center w-75 mt-4 mx-auto">Cadastrar cliente</button>
                        </form>
                    </div>
                </div>
                

                <div class="modal fade" id="registerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Alerta</h5>
                                <button type="button" class="btn-close" onClick={() => redirectToLogin()} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                {registerModalInfos.status === 'fine' ? (
                                    <div class="alert alert-success" role="alert">
                                        <h4 class="alert-heading">{registerModalInfos.title}</h4>
                                        <p>{registerModalInfos.msg}</p>
                                    </div>
                                ) : (
                                    <div class="alert alert-warning" role="alert">
                                        <h4 class="alert-heading">{registerModalInfos.title}</h4>
                                        <p>{registerModalInfos.msg}</p>
                                    </div>
                                )}
                            </div>
                            <div class="modal-footer">
                                <button className="btn btn-light"  data-bs-dismiss="modal" onClick={() => redirectToLogin()}>Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>

                {loading === true ? (
                    <div className="loading-screen text-center">
                        <img 
                        width="50"
                        src={window.BASE_HOME + '/img/loading.gif'}
                        alt="loading" />
                        <p className="fs-2 text-light">Carregando</p>
                    </div>
                ): ('')}
            </section>
            
            <section className="d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none h-100">
                <div className="card text-center w-100">
                    <div className="card-body">
                        <h1 className="mb-5">Cadastro de Cliente</h1>
                        <p>(*) Campo Obrigatório</p>
                        <form className="text-start row" onSubmit={createUser}>
                            <div className="mb-3 col-12">
                                <label forHtml="userName" className="form-label">Nome completo*</label>
                                <input type="text" value={userName}
                                    required
                                    autoComplete="none"
                                    onChange={(e) => setUserName(e.target.value)}
                                    className="form-control"
                                    id="userName" aria-describedby="userName" />
                            </div>
                            <div className="mb-3 col-12">
                                <label forHtml="userEmail" className="form-label">Email*</label>
                                <input type="email" value={userEmail}
                                    required
                                    autoComplete="none"
                                    onChange={(e) => setUserEmail(e.target.value)}
                                    className="form-control"
                                    id="userEmail" />
                            </div>
                            <div className="mb-3 col-12">
                                <label forHtml="userCPF_CNPJ" className="form-label">CPF/CNPJ*</label>
                                <CpfCnpj type="text" value={userCPF_CNPJ}
                                    required
                                    onChange={(e) => {
                                        setUserCPF_CNPJ(e.target.value);
                                        setWrongCPF_CNPJ(false);
                                    }}
                                    autoComplete="none"
                                    className="form-control"
                                    id="userCPF_CNPJ" />
                                {wrongCPF_CNPJ ? (
                                <div className="text-danger">
                                    CPF/CNPJ Inválido
                                </div>): ('')}
                            </div>
                            <div className="mb-3 col-12">
                                <label forHtml="userPass" className="form-label">Senha*</label>
                                <input type="text" value={userPass}
                                    required
                                    autoComplete="none"
                                    onChange={(e) => setUserPass(e.target.value)}
                                    className="form-control"
                                    id="userPass" />
                            </div>
                            <div className="mb-3 col-12">
                                <label forHtml="userConfirmPass" className="form-label">Confirmar Senha*</label>
                                <input type="text" 
                                    value={cUserPass}
                                    required
                                    autoComplete="none"
                                    onChange={(e) => setcUserPass(e.target.value)}
                                    className="form-control"
                                    id="userConfirmPass" />
                                {userPass !== '' && userPass !== cUserPass ? (
                                    <div className="text-danger">
                                        As senhas não são iguais! 
                                    </div>): ('')}
                            </div>
                            <div className="mb-3 col-12">
                                <label forHtml="userCel" className="form-label">Celular*</label>
                                <InputMask value={userCel}
                                    required
                                    onChange={(e) => setUserCel(e.target.value)}
                                    className="form-control"
                                    autoComplete="none"
                                    mask={"(99)99999-9999"}
                                    id="userCel" />
                            </div>
                            <div className="mb-3 col-12">
                                <label forHtml="userPhone" className="form-label">Telefone</label>
                                <InputMask value={userPhone}
                                    min="8"
                                    minLength="8"
                                    autoComplete="none"
                                    onChange={(e) => setUserPhone(e.target.value)}
                                    className="form-control"
                                    mask={"(99)99999-9999"}
                                    id="userPhone" />
                            </div>
                            <div className="mb-3 col-12" style={{width: '60%'}}>
                                <label forHtml="userCep" className="form-label">CEP* (apenas números)</label>
                                <input type="text"
                                    required
                                    max="8"
                                    maxLength="8"
                                    onBlur={(e) => getCEP(e.target.value)}
                                    className="form-control"
                                    autoComplete="none"
                                    id="userCep" />
                                {wrongCEP ? (
                                    <div className="text-danger">
                                        CEP Incorreto!
                                    </div>): ('')}
                            </div>
                            <div className="mb-3 col-12">
                                <label forHtml="userAddress" className="form-label">Endereço*</label>
                                <input type="text"
                                    required
                                    value={userLogradouro}
                                    onChange={(e) => setUserLogradouro(e.target.value)}
                                    className="form-control"
                                    autoComplete="none"
                                    id="userAddress" />
                            </div>
                            <div className="mb-3 col-12">
                                <label forHtml="userNumber" className="form-label">Número*</label>
                                <input type="text"
                                    required
                                    value={userNumber}
                                    onChange={(e) => setUserNumber(e.target.value)}
                                    className="form-control w-25"
                                    autoComplete="none"
                                    id="userNumber" />
                            </div>
                            <div className="mb-3 col-12">
                                <label forHtml="userDistrict" className="form-label">Bairro*</label>
                                <input type="text"
                                    required
                                    value={userBairro}
                                    onChange={(e) => setUserBairro(e.target.value)}
                                    className="form-control"
                                    autoComplete="none"
                                    id="userDistrict" />
                            </div>
                            <div className="mb-3 col-9">
                                <label forHtml="userCity" className="form-label">Cidade*</label>
                                <input type="text" value={userCity}
                                    required
                                    onChange={(e) => setUserCity(e.target.value)}
                                    className="form-control"
                                    autoComplete="none"
                                    id="userCity" />
                            </div>
                            <div className="mb-3 col-3">
                                <label forHtml="userState" className="form-label">Estado*</label>
                                <input type="text" value={userState}
                                    required
                                    onChange={(e) => setUserState(e.target.value)}
                                    className="form-control"
                                    autoComplete="none"
                                    id="userState" />
                            </div>
                            <div className="col-12 mb-3 mt-4">
                                <button type="submit"
                                    disabled={!validation()}
                                    className="btn btn-dark text-center w-100 mx-auto">Cadastrar cliente</button>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="modal fade" id="registerModalMob" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Alerta</h5>
                                <button type="button" class="btn-close" onClick={() => redirectToLogin()} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                {registerModalInfos.status === 'fine' ? (
                                    <div class="alert alert-success" role="alert">
                                        <h4 class="alert-heading">{registerModalInfos.title}</h4>
                                        <p>{registerModalInfos.msg}</p>
                                    </div>
                                ) : (
                                    <div class="alert alert-warning" role="alert">
                                        <h4 class="alert-heading">{registerModalInfos.title}</h4>
                                        <p>{registerModalInfos.msg}</p>
                                    </div>
                                )}
                            </div>
                            <div class="modal-footer">
                                <button className="btn btn-light"  data-bs-dismiss="modal" onClick={() => redirectToLogin()}>Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>


                {loading === true ? (
                    <div className="loading-screen text-center">
                        <img 
                        width="50"
                        src={window.BASE_HOME + '/img/loading.gif'}
                        alt="loading" />
                        <p className="fs-2 text-light">Carregando</p>
                    </div>
                ) : ('')}
            </section>
        </div>
    )
}

export default connect(state => ({ 
    loading: state.loading.loading
}))(Register);