import React from 'react';
import { connect } from 'react-redux';
import ShoppingCart from '../ShoppCart/ShoppingCart';



function Header({ cartItems }) {
    const sumTotalItems = (items) => {
        return items.reduce((acc, prev) => {
            acc = acc + prev.amount;
            return acc;
        }, 0);
    };

    return (
        <header style={{height: '50px'}}>
            <nav className="navbar navbar-dark bg-dark h-100 px-3 d-flex justify-content-between m-0 p-0">
                <div className="navbar-brand header-title">
                    <span>
                        {window.CLIENT_NAME} - Loja Virtual
                    </span>
                </div>
                <div>
                    <button type="button" className="btn btn-outline-secondary btn-sm"
                        style={{fontSize: '1.1rem'}} data-bs-toggle="modal" data-bs-target="#cartModal">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                        </svg>
                        <span className="badge badge-danger">{sumTotalItems(cartItems)}</span>
                    </button>
                    
                    <div className="modal fade" id="cartModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <ShoppingCart />
                    </div>
                </div>
            </nav>
        </header>
    )
}


export default connect(state => ({ cartItems: state.cart.cartItems }))(Header);