
export class CartItem {

    constructor(id, name, value, amount, type='product', itens=null) {
        this.id = id;
        this.name = name;
        this.value = value;
        this.amount = amount;
        this.type = type;
        this.itens = itens;
    }
}
