import React, { useEffect, useState } from 'react';
import ClientHttp from '../../services/HttpClient'
import './Calendar.css';
import { connect } from 'react-redux';
import * as ProductActions from '../../store/actions/products';
import * as LoadingAction from '../../store/actions/loading';
import * as DataFestaActions from '../../store/actions/dataFesta';
import * as CartActions from '../../store/actions/cart';
import * as Lightpick from 'lightpick';
import Moment from 'moment';

let INPUT_DATE_ELEMENT = null;

function Calednar({ selected, products, filter, dataFesta, dispatch }) {
    const [dateOpt, setDateOpt] = useState(JSON.parse(localStorage.getItem('radio-option-selected')));
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [dateStartView, setDateStartView] = useState('');
    const [dateStartMultView, setDateStartMultView] = useState('');
    const [dateEndMultView, setDateEndMultView] = useState('');
    const [dateRange, setDateRange] = useState([]);

    useEffect(() => {
        const $combos = document.querySelector("#combos");
        const $produtos = document.querySelector("#produtos");
        const $combosMobile = document.querySelector("#combos-mobile");
        const $produtosMobile = document.querySelector("#produtos-mobile");

        if ($combos.id === selected) {
            $produtos.classList.remove('active');
            $combos.classList.add('active');
            $produtosMobile.classList.remove('active');
            $combosMobile.classList.add('active');
        }

        if ($produtos.id === selected) {
            $combos.classList.remove('active');
            $produtos.classList.add('active');
            $combosMobile.classList.remove('active');
            $produtosMobile.classList.add('active');
            
        }
    });

    const getCombos = (startDate, finalDate=null) => {
        if (finalDate) {
            dispatch(ProductActions.cleanCombos());
            ClientHttp.get(`/GetCombos/${startDate}/${finalDate}`).then(res => {
                const data = res.data.result[0];
                dispatch(ProductActions.setCombos(data));
                dispatch(LoadingAction.setLoading(false));
            }, err => {
                dispatch(LoadingAction.setLoading(false));
                alert('Ops... \nOcorreu um erro ao buscar os brinquedos.\nTente novamente mais tarde.');
            });
        } else {
            dispatch(ProductActions.cleanCombos());
            ClientHttp.get(`/GetCombos/${startDate}/${startDate}`).then(res => {
                const data = res.data.result[0];
                dispatch(ProductActions.setCombos(data));
                dispatch(LoadingAction.setLoading(false));
            }, err => {
                dispatch(LoadingAction.setLoading(false));
                alert('Ops... \nOcorreu um erro ao buscar os brinquedos.\nTente novamente mais tarde.');
            });
        }
    };

    const renderProduct = (render, target) => {
        dispatch(ProductActions.selectProductsList(render))
    }

    const mapData = (data) => {
        const productsMapped = [];

        for (let i = 0; i < data.length; i++) {
            if (productsMapped.length === 0) {
                if (parseInt(data[i].DISPONIVEL) <= 0) {
                    productsMapped.push(data[i]);
                    productsMapped[i].DISPONIVEL = parseInt(data[i].DISPONIVEL);
                    productsMapped[i].STATUS = 0;
                } else {
                    productsMapped.push(data[i]);
                    productsMapped[i].DISPONIVEL = parseInt(data[i].DISPONIVEL);
                    productsMapped[i].STATUS = 1;
                }
            } else {
                const itemIndex = productsMapped.findIndex(item => item.CODIGO === data[i].CODIGO);

                if (itemIndex !== -1) {
                    if (productsMapped[itemIndex].STATUS === 1 && parseInt(data[i].DISPONIVEL) > 0) {
                        productsMapped[itemIndex].DISPONIVEL += parseInt(data[i].DISPONIVEL);
                    } else {
                        productsMapped[itemIndex].STATUS = 0;
                        productsMapped[itemIndex].DISPONIVEL += parseInt(data[i].DISPONIVEL);
                    }
                } else {
                    if (parseInt(data[i].DISPONIVEL) <= 0) {
                        productsMapped.push(data[i]);
                        productsMapped[i].DISPONIVEL = parseInt(data[i].DISPONIVEL);
                        
                        productsMapped[i].STATUS = 0;
                    } else {
                        productsMapped.push(data[i]);
                        productsMapped[i].DISPONIVEL = parseInt(data[i].DISPONIVEL);
                        productsMapped[i].STATUS = 1;
                    }
                }
            } 
        }
        return productsMapped;

    };

    useEffect(() => {
        setDateStart('');
        setDateEnd('');
        setDateStartView('');
        setDateStartMultView('');
        setDateEndMultView('');

        if (dateOpt === null) {
            setDateOpt(true);
            localStorage.setItem('radio-option-selected', true);
        }

        if (dateOpt) {
            document.querySelector('#start-multi')?.remove();
            document.querySelector('#end-multi')?.remove();

            if (INPUT_DATE_ELEMENT) {
                INPUT_DATE_ELEMENT.destroy();
            }
            
            INPUT_DATE_ELEMENT = new Lightpick({
                field: document.getElementById('start-single'),
                singleDate: true,
                minDate: new Date().getDate(),
                lang: 'pt',
                onSelect: function(date) {
                    const dateFormatted = date.format('DD/MM/YYYY');
                    setDateStartView(dateFormatted);

                    const start = date.format('YYYYMMDD');
                    setDateRange([dateFormatted]);
                    setDateStart(start);
                }
            });
            if (dataFesta.length > 0 && dataFesta.length < 2) {
                document.getElementById('start-single').value = dataFesta[0] || '';
            }
        } else {
            document.querySelector('#start-single')?.remove();

            if (INPUT_DATE_ELEMENT) {
                INPUT_DATE_ELEMENT.destroy();
            }

            INPUT_DATE_ELEMENT = new Lightpick({
                field: document.getElementById('start-multi'),
                secondField: document.getElementById('end-multi'),
                singleDate: false,
                minDate: new Date().getDate(),
                lang: 'pt',
                onSelect: (start, end) => {
                    if (start && end) {
                        const dateFormattedStart = start.format('DD/MM/YYYY');
                        const dateFormattedEnd = end.format('DD/MM/YYYY');
                        setDateStartMultView(dateFormattedStart);
                        setDateEndMultView(dateFormattedEnd);

                        const day = 1000*60*60*24;
                        const date1 = new Date(start.format('YYYY-MM-DD'));
                        const date2 = new Date(end.format('YYYY-MM-DD'));
                        const diff = (date2.getTime()- date1.getTime()) / day;
                        const rangeDatesTemp = [];

                        for (let i=0;i <= diff; i++) {
                            const xx = date1.getTime()+day*i;
                            const newDate = new Date(xx);
                            if (i !== 0) {
                                rangeDatesTemp.push(Moment(newDate).format('DD/MM/YYYY'));
                            }
                        }

                        rangeDatesTemp.push(dateFormattedEnd);
                        setDateRange(rangeDatesTemp);


                        const startD = start.format('YYYYMMDD');
                        const endD = end.format('YYYYMMDD');
                        setDateStart(startD);
                        setDateEnd(endD);
                    }
                }
            });

            if (dataFesta.length > 1) {
                document.getElementById('start-multi').value = dataFesta[0] || '';
                document.getElementById('end-multi').value = dataFesta[dataFesta.length -1] || '';
            }
        }
    }, [dateOpt]);

    const formatDateToView = (date) => {
       return Moment(date, 'DD/MM/YYYY',).format('YYYY-MM-DD');
    }

    const onChangeDatesMobile = (startDate, finalDate=null) => {
        if (dateOpt) {
            const date = new Date(startDate);
            const formattedDate = Moment(date).zone(0).utc().format('DD/MM/YYYY');
            const start = Moment(date).zone(0).utc().format('YYYYMMDD');

            setDateStartView(formattedDate);
            setDateRange([formattedDate]);
            setDateStart(start);
        } else {
            if (startDate && finalDate) {
                // const dateFormattedStart = Moment(new Date(startDate)).zone(0).utc().format('DD/MM/YYYY');
                const dateFormattedEnd = Moment(new Date(finalDate)).zone(0).utc().format('DD/MM/YYYY');
                // setDateStartMultView(dateFormattedStart);
                setDateEndMultView(dateFormattedEnd);

                const day = 1000*60*60*24;
                const date1 = new Date(startDate);
                const date2 = new Date(finalDate);
                const diff = (date2.getTime()- date1.getTime()) / day;
                const rangeDatesTemp = [];

                for (let i=0;i <= diff; i++) {
                    const xx = date1.getTime()+day*i;
                    const newDate = new Date(xx);
                    if (i !== 0) {
                        rangeDatesTemp.push(Moment(newDate).format('DD/MM/YYYY'));
                    }
                }

                rangeDatesTemp.push(dateFormattedEnd);
                setDateRange(rangeDatesTemp);


                const startD = Moment(new Date(startDate)).zone(0).utc().format('YYYYMMDD');
                console.log('Start D => ', startD)
                const endD = Moment(new Date(finalDate)).zone(0).utc().format('YYYYMMDD');
                console.log('End D => ', endD)
                setDateStart(startD);
                setDateEnd(endD);
            } 
            else if (startDate) {
                const dateFormattedStart = Moment(new Date(startDate)).zone(0).utc().format('DD/MM/YYYY');
                setDateStartMultView(dateFormattedStart);
            }
        }
    }

    const getStock = (startDate, endDate) => {
        dispatch(ProductActions.cleanProducts());
        dispatch(CartActions.clearCart());
        dispatch(DataFestaActions.clearDates());

        if (endDate) {
            dispatch(LoadingAction.setLoading(true));
            ClientHttp.get(`/GetEstoque/${startDate}/${endDate}`).then(res => {
                getCombos(startDate, endDate);
                const products = mapData(res.data.result[0]);

                dispatch(ProductActions.cleanProducts());
                dispatch(ProductActions.setProducts(products));
                dispatch(DataFestaActions.setRangeDate(dateRange));
            }, err => {
                dispatch(LoadingAction.setLoading(false));
                alert('Ops... \nOcorreu um erro ao buscar os brinquedos.\nTente novamente mais tarde.');
            });
        } else {
            dispatch(LoadingAction.setLoading(true));
            ClientHttp.get(`/GetEstoque/${startDate}/${startDate}`).then(res => {
                getCombos(startDate, startDate);
                const products = res.data.result[0];

                dispatch(ProductActions.cleanProducts());
                dispatch(ProductActions.setProducts(products));
                dispatch(DataFestaActions.setRangeDate(dateRange));
            }, err => {
                dispatch(LoadingAction.setLoading(false));
                alert('Ops...\nOcorreu um erro ao buscar os brinquedos.\nTente novamente mais tarde.');
            });
        }
    };

    const changeProducts = (type, target) => {
        if (document.getElementById('input-search')) {
            document.getElementById('input-search').value = '';
        }
        
        renderProduct(type, target);
        filter('');
    };

    const changeOptDates = (option) => {
        localStorage.setItem('radio-option-selected', option)
        setDateOpt(option);
    }

    return (
        <div className="card mt-m-0 mt-sm-0 mt-xs-0 mx-auto mt-lg-1 mt-xl-1 mt-xxl-1 card-bg-color card-sizer">
            <div className="card-body p-0 px-auto">
                <section className="calendar-container d-sm-none d-md-block d-none d-sm-block">
                    <header className="d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-md-between 
                        justify-content-lg-between justify-content-xl-between justify-content-xxl-between">
                        <p className="m-0" style={{fontSize: "28px", fontWeight: 500}}>Escolha a melhor data para a sua festa!</p>
                        <img className="mt-1" src={`${window.BASE_IMAGES}/logo.jpg`} alt="logo" width="180" style={{float: 'right'}}/>
                    </header>
                    <article>
                        <div className="radio-container">
                            <p className="m-0 mb-1" style={{fontSize: "16px", fontWeight: 400}}>Por quantos dias deseja alugar os brinquedos?</p>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                    type="radio"
                                    checked={dateOpt === true}
                                    onClick={() => changeOptDates(true)}
                                    name="flexRadioDefault"
                                    id="flexRadioAlugloc1" />
                                <label className="form-check-label" for="flexRadioAlugloc1">
                                    Apenas um dia
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioAlugloc2"
                                    checked={dateOpt === false}
                                    onClick={() => changeOptDates(false)} />
                                <label className="form-check-label" for="flexRadioAlugloc2">
                                    Mais de um dia
                                </label>
                            </div>
                        </div>
                        <div>
                            <p className="m-0 mb-2">Selecione a data da festa</p>

                            {dateOpt ? (
                                <div className="d-inline">
                                    <label htmlFor="start-single" style={{marginRight: '5px'}}>Início</label>
                                    <input id="start-single" value={dateStartView} style={{marginRight: '308px'}}
                                        type="text" placeholder="dd/mm/aaaa" autoComplete="off" />
{/* 
                                    <label htmlFor="end" style={{marginLeft: '20px', marginRight: '5px'}}>Fim</label>
                                    <input id="end" type="text" value={dateEndMultView} placeholder="dd/mm/aaaa" autoComplete="off" disabled={dateOpt} /> */}
                                </div>
                            ) : (
                                <div className="d-inline">

                                    <label htmlFor="start-multi" style={{marginRight: '5px'}}>Início</label>
                                    <input id="start-multi" value={dateStartMultView} type="text" placeholder="dd/mm/aaaa" autoComplete="off" />

                                    <label htmlFor="end-multi" style={{marginLeft: '20px', marginRight: '5px'}}>Fim</label>
                                    <input id="end-multi" value={dateEndMultView} type="text" placeholder="dd/mm/aaaa" autoComplete="off" disabled={dateOpt} />
                                </div>
                            )}

                            <button className="btn btn-dark" style={{marginLeft: '20px'}}
                                onClick={() => getStock(dateStart, dateEnd)}
                                disabled={
                                    !((dateOpt && dateStart !== '') || (!dateOpt && dateStart !== '' && dateEnd !== ''))}>
                                Pesquisar Brinquedos Disponíveis</button>
                        </div>
                    </article>
                    <hr style={{margin: "2px 0 0 1px"}} />
                    <article className="text-center pb-0">
                        <p className="fs-5 m-0">Selecionar</p>
                        <div className="btn-group pb-1" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-outline-dark" id="combos"
                                onClick={(e) => changeProducts('combos', e.target)}>Combos</button>
                            <button type="button" className="btn btn-outline-dark" id="produtos"
                                onClick={(e) => changeProducts('produtos', e.target)}>Produtos</button>
                        </div>
                    </article>
                    {products.length > 0 ? (
                        <div className="w-75 mx-auto my-1">
                            <label className="form-label">Pesquisa por nome</label>
                            <input type="text" className="form-control" id="input-search"
                                onChange={e => filter(e.target.value)}
                                placeholder="Ex.: Cama Elástica" />
                        </div>
                    ) : ('')}
                </section>
                <section className="d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none w-100">
                    <div className="row w-100 p-0 m-0">
                        <div className="col-12 mb-2">
                            <img className="mt-1 w-100" src={`${window.BASE_IMAGES}/logo.jpg`} alt="logo"/>
                        </div>
                        <div className="col-12 mb-2">
                            <p className="m-0 text-center" style={{fontSize: "28px", fontWeight: 500}}>Escolha a melhor data para a sua festa!</p>
                        </div>
                        <div className="col-12 mb-2 text-center">
                            <div className="radio-container">
                                <p className="m-0 mb-1" style={{fontSize: "16px", fontWeight: 400}}>Por quantos dias deseja alugar os brinquedos?</p>
                                <input id="singledate"
                                    type="radio"
                                    name="calendar-date"
                                    value="single"
                                    checked={dateOpt === true}
                                    onClick={() => changeOptDates(true)} />
                                <label htmlFor="singledate">Apenas um dia</label>
                                <input id="manyDias"
                                    style={{marginLeft: '20px'}}
                                    type="radio"
                                    name="calendar-date"
                                    value="many"
                                    checked={dateOpt === false}
                                    onClick={() => changeOptDates(false)} />
                                <label htmlFor="manyDias">Mais de um dia</label>
                            </div>
                        </div>
                        <div className="col-12 mb-2 text-center">
                            <div className="w-100 d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block">
                                <p className="m-0 mb-2">Selecione a data da festa</p>

                                {dateOpt ? (
                                    <div className="d-inline">
                                        <label htmlFor="start-single" style={{marginRight: '5px'}}>Início</label>
                                        <input id="start-single" value={dateStartView}
                                            type="text" placeholder="dd/mm/aaaa" autoComplete="off" />

                                        <label htmlFor="end" style={{marginLeft: '20px', marginRight: '5px'}}>Fim</label>
                                        <input id="end" type="text" placeholder="dd/mm/aaaa" autoComplete="off" disabled={dateOpt} />
                                    </div>
                                ) : (
                                    <div className="d-inline">

                                        <label htmlFor="start-multi" style={{marginRight: '5px'}}>Início</label>
                                        <input id="start-multi" value={dateStartMultView} type="text" placeholder="dd/mm/aaaa" autoComplete="off" />

                                        <label htmlFor="end-multi" style={{marginLeft: '20px', marginRight: '5px'}}>Fim</label>
                                        <input id="end-multi" value={dateEndMultView} type="text" placeholder="dd/mm/aaaa" autoComplete="off" disabled={dateOpt} />
                                    </div>
                                )}
                            </div>
                            <div className="d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none">
                                <p className="m-0 mb-2">Selecione a data da festa</p>
                                {dateOpt ? (
                                    <div className="">
                                        <label htmlFor="start-single" >Início</label><br/>
                                        <input type="date" id="start-single" value={formatDateToView(dateStartView)}
                                            data-inputmask-mask="DD/MM/YYYY" 
                                            onChange={(e) => onChangeDatesMobile(e.target.value) }
                                            autoComplete="off" />
                                        <br/><br/>
                                        <label htmlFor="end">Fim</label><br/>
                                        <input type="date" id="end" 
                                            placeholder="dd/mm/aaaa" autoComplete="off" disabled={dateOpt} />
                                    </div>
                                ) : (
                                    <div className="">
                                        <label htmlFor="start-multi">Início</label><br/>
                                        <input type="date" id="start-multi"
                                            onChange={(e) => onChangeDatesMobile(e.target.value)  }
                                            value={formatDateToView(dateStartMultView)} placeholder="dd/mm/aaaa" autoComplete="off" />
                                        <br/><br/>
                                        <label htmlFor="end-multi">Fim</label><br/>
                                        <input type="date" id="end-multi" 
                                            onChange={(e) => onChangeDatesMobile(formatDateToView(dateStartMultView), e.target.value) }
                                            value={formatDateToView(dateEndMultView)} placeholder="dd/mm/aaaa" autoComplete="off" disabled={dateOpt} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-12 mb-2 text-center mt-4">
                            <button className="btn btn-sm btn-dark mx-auto"
                                    onClick={() => getStock(dateStart, dateEnd)}
                                    disabled={
                                        !((dateOpt && dateStart !== '') || (!dateOpt && dateStart !== '' && dateEnd !== ''))}>
                                Pesquisar Brinquedos Disponíveis
                            </button>
                        </div>
                        <hr className="col-12 p-0 m-0" style={{margin: "2px 0 0 1px"}} />
                        <article className="col-12 text-center pb-0">
                            <p className="fs-5 m-0">Selecionar</p>
                            <div className="btn-group pb-1" role="group" aria-label="Basic example">
                                <button type="button" className="btn btn-outline-dark" id="combos-mobile"
                                    onClick={(e) => renderProduct('combos', e.target)}>Combos</button>
                                <button type="button" className="btn btn-outline-dark" id="produtos-mobile"
                                    onClick={(e) => renderProduct('produtos', e.target)}>Produtos</button>
                            </div>
                        </article>
                        {products.length > 0 ? (
                            <div className="col-12 w-100 my-1">
                                <label className="form-label">Pesquisa por nome</label>
                                <input type="text" className="form-control" id="input-search"
                                    onChange={e => filter(e.target.value)}
                                    placeholder="Ex.: Cama Elástica" />
                            </div>
                        ) : ('')}
                    </div>
                </section>
            </div>
        </div>
    )
}

export default connect(state => ({
    products: state.marketplace.products,
    selected: state.marketplace.selected,
    dataFesta: state.dataFesta.date,
    loading: state.loading.loading
}))(Calednar);
