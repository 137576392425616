
export function setUnicDate(date) {
    return {
        type: "SET_UNIC_DATE",
        date
    }
}

export function setRangeDate(date) {
    return {
        type: "SET_DATE_RANGE",
        date
    }
}


export function clearDates() {
    return {
        type: "CLEAR_DATE"
    }
}