

export function calculaDistanciaFromCEP(destination) {
    return new Promise((resolve) => {
        if (window.FRETE) {
            // eslint-disable-next-line no-undef
            const service = new google.maps.DistanceMatrixService();
    
            service.getDistanceMatrix({
              origins: [window.ORIGIN],
              destinations: [destination],
              travelMode: 'DRIVING',
              // eslint-disable-next-line no-undef
              unitSystem: google.maps.UnitSystem.METRIC,
              avoidHighways: false,
              avoidTolls: false
            }).then(res => {
                const distanceMetrix = res.rows[0].elements[0];
                const distance = distanceMetrix.distance.text.split(' ')[0];
                resolve(parseFloat(distance.replace(',', '.')).toFixed(2));
            });
        }
    });
}