const INITIAL_STATE = {
    loading: false
};



export default function reducer(state=INITIAL_STATE, action) {

    if (action.type === 'SET_LOADING') {
        return { ...state, loading: action.loading };
    }

    return state;
} 