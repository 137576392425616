import React from 'react';
import { connect } from 'react-redux';
import * as CartActions from '../../store/actions/cart';
import { Link } from 'react-router-dom';


import './ShoppingCart.css';

function ShoppingCart({ cartItems, products, dispatch }) {
    const calcTotal = (items) => {
        return items.reduce((acc, prev) => {
            acc = acc + prev.amount * parseFloat(prev.value);
            return acc;
        }, 0);
    };

    const decreaseAmountValue = (item) => {
        dispatch(CartActions.decreaseAmountValue(item));
    };

    const removeItemFromCart = (item) => {
        dispatch(CartActions.removeCartitem(item));
    };

    const increaseAmountValue = (item) => {
        const product = products.find(prd => prd.CODIGO === item.id);
        if (product) {
            dispatch(CartActions.increaseAmountValue(item, product.DISPONIVEL));
        }
    }

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Seus Pedidos</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <ul className="list-group d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block">
                        {cartItems.length > 0 ? (
                            <div>
                                {cartItems.map((item, index) => (
                                    <li key={index} className="list-group-item w-100 d-flex justify-content-between">
                                        <p className="m-0 d-inline" style={{fontSize: '14px', width: '170px'}}>
                                            <span className="text-break">{item.name}</span>
                                        </p>
                                        <div className="d-inline">
                                            <span style={{marginRight: '14px', fontSize: '14px'}}>R$ {parseFloat(item.value) * item.amount}</span>
                                            <div className="d-inline ">
                                                <i className="bi bi-caret-left-fill btn-qnt"
                                                    onClick={() => decreaseAmountValue(item)}
                                                    style={{ fontSize: '20px'}}></i>
                                                <input style={{ width: "30px", textAlign: 'center' }}
                                                    value={item.amount} type="text" disabled min="0" />
                                                <i className="bi bi-caret-right-fill btn-qnt"
                                                    onClick={() => increaseAmountValue(item)}
                                                    style={{ fontSize: '20px'}}></i>
                                                <a href="#" className="text-danger"
                                                    onClick={() => removeItemFromCart(item) }
                                                    style={{fontSize: '10px', marginLeft: '15px'}}>Remover todos</a>
                                            </div>
                                        </div>
                                    </li>
                                ))}

                                <p className="m-0 mt-2 text-end">Total:  R${calcTotal(cartItems)}</p>
                            </div>
                        ) : (<li className="list-group-item"><p>Nenhum produto foi adicionado</p></li>)}
                    </ul>
                    <ul className="list-group d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none">
                        {cartItems.length > 0 ? (
                            <div>
                                {cartItems.map((item, index) => (
                                    <li key={index} className="list-group-item w-100 border">
                                        <p className="m-0 w-100 text-break">
                                        {item.amount} un. - {item.name}
                                        </p>

                                        <div className="d-flex justify-content-between">
                                            <span className="text-success">R$ {parseFloat(item.value) * item.amount}</span>
                                            <div className="d-inline">
                                                <a href="#" className="text-danger"
                                                    onClick={() => removeItemFromCart(item) }
                                                    style={{fontSize: '16px', marginLeft: '15px', textDecoration: 'none'}}><i class="bi bi-trash-fill"></i> Remover</a>
                                            </div>
                                        </div>
                                    </li>
                                ))}

                                <p className="m-0 mt-2 mb-4 text-end">Total:  R${calcTotal(cartItems)}</p>
                            </div>
                        ) : (<li className="list-group-item"><p>Nenhum produto foi adicionado</p></li>)}
                    </ul>
                </div>
                <div className="modal-footer d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Voltar para os produtos</button>
                    {cartItems.length > 0 ? (
                        <Link to="/access-point" className="btn btn-primary">Finalizar Pedido</Link>
                    ): (
                        <Link className="btn btn-primary disabled-link">Finalizar Pedido</Link>
                    )}
                </div>
                <div className="modal-footer d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none d-flex justify-content-between">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Ver produtos</button>
                    {cartItems.length > 0 ? (
                        <Link to="/access-point" className="btn btn-primary">Finalizar Pedido</Link>
                    ): (
                        <Link className="btn btn-primary disabled-link">Finalizar Pedido</Link>
                    )}
                </div>
            </div>
        </div>
    );
}


export default connect(state => ({ cartItems: state.cart.cartItems, products: state.marketplace.products }))(ShoppingCart);
