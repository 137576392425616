import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import Auth from './app/components/Authentication/Auth';
import Login from './app/components/Authentication/User/Login';
import Register from './app/components/Authentication/User/Register';
import Schedule from './app/components/Confirmation/Agenda';
import './index.css';

import configureStore from './app/store';
const { persistor, store } = configureStore();


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename={window.FOLDER_NAME}>
          <Switch>
            <Route exact path="/">
              <App />
            </Route>
            <Route exact path="/access-point">
              <Auth />
            </Route>
            <Route exact path="/login"> 
              <Login />
            </Route>
            <Route exact path="/confirm" >
              <Schedule />
            </Route>
            <Route exact path="/register">
              <Register />
            </Route>
          </Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
