import React from 'react';
import { connect } from 'react-redux';
import * as CartActions from '../../store/actions/cart';
import './Product.css';

function Product({product, dispatch}) {
    const addItemsToCart = (item) => {
        dispatch(CartActions.setCartItem(item));
        creatToast();
    };

    const errorImage = (e) => {
        e.target.src = window.BASE_HOME + '/img/noImage.png';
    }

    const creatToast = () => {
        const div = document.createElement('div');
        div.id = 'toast-id';
    
        div.innerHTML = `<div class="position-fixed top-0 end-0 p-3" style="z-index: 5; top: 5%!important">
            <div id="liveToast" style="background-color: #ffff9e" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header" style="background-color: #ffff9e">
                <strong class="me-auto">Item Adicionado</strong>
            </div>
            <div class="toast-body">
                Um item foi adicionado no carrinho de compras!
            </div>
            </div>
        </div>`;

        document.querySelector('body').appendChild(div);

        setTimeout(() => {
            document.querySelector('#toast-id').remove();
        }, 2500);
    }


    const zoomImage = () => { 
        const $btn = document.createElement('button');
        $btn.setAttribute('data-bs-toggle', 'modal');
        $btn.setAttribute('data-bs-target', `#zoom-image-${product.CODIGO}`);
        document.body.appendChild($btn);
        $btn.click();
        $btn.remove();
    };

    return (
        <div className="card col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 card-product">
            <img src={`${window.BASE_IMAGES}/${product.CODIGO}.jpg`} 
                onError={(e) => errorImage(e)}
                onClick={(e) => zoomImage() }
                className="card-img-top product-image mx-auto d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block"
                alt="produto" style={{width: '185px', height: '140px'}}  />
            <img src={`${window.BASE_IMAGES}/${product.CODIGO}.jpg`}
                onClick={(e) => zoomImage() }
                onError={(e) => errorImage(e)} 
                className="d-lg-none product-image d-xl-block d-xl-none d-xxl-block d-xxl-none"
                alt="produto"  />
            <div className="card-body text-lg-center text-xl-center text-xxl-center">
                <h5 className="card-title">{product.DESCRICAO}</h5>
                <p>R$ {product.VALOR}</p>
                {product.STATUS === 0 || product.DISPONIVEL <= 0 ? (
                        <p className="card-text text-uppercase text-danger">PRODUTO INDISPONÍVEL</p>
                    ): (
                        <p className="card-text text-uppercase text-success">PRODUTO DISPONÍVEL</p>
                    )}
                <div className="d-lg-flex d-xl-flex d-xxl-flex justify-content-lg-center justify-content-xl-center justify-content-xxl-center">
                    <button id="liveToastBtn"
                        disabled={product.STATUS === 0 || product.DISPONIVEL <= 0 }
                        className="btn btn-success d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block"
                        onClick={() => addItemsToCart(product)}>Adicionar</button>

                    <button id="liveToastBtn"
                        disabled={product.STATUS === 0 || product.DISPONIVEL <= 0 }
                        className="btn btn-success w-100 d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none"
                        onClick={() => addItemsToCart(product)}>Adicionar</button>
                </div>
            </div>



            <div className="modal fade" id={`zoom-image-${product.CODIGO}`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div class="modal-body m-0 p-0">
                        <img src={`${window.BASE_IMAGES}/${product.CODIGO}.jpg`} 
                            onError={(e) => errorImage(e)}
                            className="w-100 h-auto"
                            alt="produto"  />
                    </div>
                    <div className="modal-footer m-0 p-0">
                        <button type="button" className="btn btn-sm btn-dark" data-bs-dismiss="modal">Fechar</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(state => ({ cartItems: state.cart.cartItems }))(Product);