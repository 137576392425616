import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Agenda } from '../../model/Agenda';
import { ItensAgenda } from '../../model/ItensAgenda';
import HttpClient from '../../services/HttpClient';
import { useHistory } from 'react-router-dom';
import * as LoadingAction from '../../store/actions/loading';

import * as CartAction from '../../store/actions/cart';
import * as ProductAction from '../../store/actions/products';
import * as DateAction from '../../store/actions/dataFesta';

import { calculaDistanciaFromCEP } from './CalculoDistancia'; 

const AREAS = [
    '----',
    'ABERTA',
    'COBERTA',
    'SALAO',
    'CONDOMINIO',
    'CASA'
];

const VOLTAGENS = [
    '----',
    '110',
    '220'
];

const GRAU = [
    'Filho(a)',
    'Esposo(a)',
    'Sogro(a)',
    'Tio(a)',
    'Amigo(a)',
    'Neto(a)',
    'Primo(a)',
    'Sobrinho(a)',
    'Afilhado(a)',
    'Enteado(a)',
    'Outros'
];

const TEMPO_FESTA = window.TEMPO_FESTA;

function Schedule({ user, cartItems, loading, dataFesta, dispatch }) {
    const history = useHistory();
    const[newAddress, setNewAdress] = useState(false);
    const [userName, setUserName] = useState(user.NOME);
    const [userEmail, setUserEmail] = useState(user.EMAIL);
    const [userCel, setUserCel] = useState(user.CELULAR);
    const [userPhone, setUserPhone] = useState(user.FONE);
    const [userCEP, setUserCEP] = useState(user.CEP.split('-')[0]);
    const [cepToSend, setCEPtoSend] = useState(null);
    const [userBairro, setUserBairro] = useState(user.BAIRRO);
    const [userLogradouro, setUserLogradouro] = useState(user.ENDERECO);
    const [userCity, setUserCity] = useState(user.CIDADE);
    const [userNumber, setUserNumber] = useState('');
    const [userState, setUserState] = useState(user.ESTADO);
    const [pagamentos, setPagamentos] = useState([]);
    const [stopEffect, setStopEffect] = useState(false);
    const [formaPagamento, setFormaPagamento] = useState('');
    const [area, setArea] = useState('----');
    const [voltagem, setVoltagem] = useState('----');
    const [horaInicial, setHoraInicial] = useState('');
    const [horaFinal, setHoraFinal] = useState('');
    const [dataInicialFesta, setDataInicialFesta] = useState(dataFesta[0]);
    const [dataFinalFesta, setDataFinalFesta] = useState(dataFesta.length > 1 ? dataFesta[dataFesta.length - 1] : dataFesta[0]);
    const [grauParent, setGrauParent] = useState('');
    const [idade, setIdade] = useState(0);
    const [eventName, setEventName] = useState('');
    const [observacao, setObservacao] = useState('');
    const [registerModalInfos, setRegisterModalInfos] = useState({});
    const [frete, setFrete] = useState(0);

    const [wrongCEP, setWrongCEP] = useState(false);
    const [canContinue, setCanContinue] = useState(false);

    useEffect(() => {
        if (!stopEffect) {
            HttpClient.get('/GetMeioPgto').then(res => {
                setStopEffect(true);
                if (res.status === 200) {
                    const data = res.data.result[0];
                    setPagamentos(data);
                }
            });

            calculaFrete();
        }
    }, [stopEffect]);

    useEffect(() => {
        setCanContinue(validation() && validateAddress());
    })

    const getCEP = (cep) => {
        setWrongCEP(false);

        if (cep && cep !== '' && cep.trim() !== '' && cep.length === 8) {
            fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(response => response.json())
            .then(data => {
                if (data.erro) {
                    setWrongCEP(true);
                } else {
                    setCEPtoSend(data.cep);
                    const cepSplitted = data.cep.split('-');
                    setUserCEP(`${cepSplitted[0]}${cepSplitted[1]}`);
                    setUserBairro(data.bairro);
                    setUserCity(data.localidade);
                    setUserState(data.uf);
                    setUserLogradouro(data.logradouro);
                    calculaFrete();
                }
            });
        }
    };

    const calcTotal = () => {
        return parseFloat(cartItems.reduce((acc, prev) => {
            acc = acc + prev.amount * parseFloat(prev.value);
            return acc;
        }, 0)).toFixed(2);
    };

    const getAcrescimo = () => {
        return pagamentos.find(item => item.CODIGO === formaPagamento)?.ACRESCIMO || 0;
    };

    const validation = () => {
        if (userName !== '' && userEmail !== '' && 
            userCel !== '' && userCEP !== '' && userBairro !== '' &&
            userLogradouro !== '' && userCity !== '' &&
            userState !== '' && formaPagamento !== '' &&
            horaInicial !== '' && horaFinal !== '' &&
            eventName !== '') {
            
            return true;
        }

        return false;
    };

    const getFormaPagamento = (codigo) => {
        return pagamentos.find(item => item.CODIGO === codigo).DESCRICAO;
    };

    const setHoraInicialFinal = (time) => {
        const timeSplitted = time.split(':');
        const hour = parseInt(timeSplitted[0]);
        const minute = parseInt(timeSplitted[1]) > 9 ? parseInt(timeSplitted[1]) : `0${parseInt(timeSplitted[1])}`;

        let horaFinal = hour;
        for (let c = 0; c < TEMPO_FESTA; c++) {
            if (horaFinal + 1 === 24) {
                horaFinal = 0;
            } else if (horaFinal + 1 > 24 ) {
                horaFinal = 0;
                horaFinal += 1;
            } else {
                horaFinal += 1;
            }
        }

        setHoraInicial(time);
        setHoraFinal(horaFinal > 9 ? `${horaFinal}:${minute}` : `0${horaFinal}:${minute}`);

    };

    const addStr = (str, index, stringToAdd) => {
        return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
    }

    const calcAcrescimo = () => {
        const acrescimo = ((parseFloat(getAcrescimo()) / 100) * calcTotal()).toFixed(2)
        return (parseFloat(calcTotal()) + parseFloat(acrescimo)).toFixed(2)
    };

    const getTaxaAcrescimo = () => {
        return ((parseFloat(getAcrescimo()) / 100) * calcTotal()).toFixed(2).replace('.',',');
    }

    const getValoraPagar = () => {
        if (getAcrescimo() != 0) {
            const result = parseFloat(calcAcrescimo()) + frete;

            return result.toFixed(2).replace('.', ',');
        } else {
            const result = parseFloat(calcTotal()) + frete;
            return result.toFixed(2).replace('.', ',');
        }
    }

    const convertToReal = (item, amount) => {
        return (parseFloat(item) * amount).toFixed(2).replace('.', ',');
    }

    const createButton = (id) => {
        const buttonElem = document.createElement('button');
        buttonElem.type = 'button';
        buttonElem.setAttribute('data-bs-toggle', 'modal');
        buttonElem.setAttribute('data-bs-target', id);
        buttonElem.style.display = 'none';

        document.body.appendChild(buttonElem);

        return buttonElem;
    }

    const alertTrigger = (status, title, msg, redirect=true) => {
        const button = createButton('#registerModal');

        setRegisterModalInfos({
            status,
            title,
            msg,
            redirect
        });

        button.click();
        button.remove();
    }

    const createAgenda = () => {
        let comboTxt = '';
        cartItems.forEach(item => {
            if (item.type === 'combo') {
                comboTxt = `\nCOMBO: ${item.name}`;
            }
        })

        const agenda = new Agenda(
            user.CODIGO, 
            dataInicialFesta, 
            horaInicial, 
            horaFinal, 
            userLogradouro, 
            userNumber, 
            userCity,
            userBairro, 
            userState, 
            getTaxaAcrescimo(), 
            0, 
            getValoraPagar(), 
            getFormaPagamento(formaPagamento),
            dataFinalFesta, 
            voltagem, 
            area, 
            cepToSend || addStr(userCEP, 5, '-'), 
            eventName, grauParent, idade,
            comboTxt !== '' ? observacao + comboTxt : observacao,
            frete.toFixed(2).replace('.',','));
        
        dispatch(LoadingAction.setLoading(true));
        HttpClient.put('/InsereAgendas', [agenda]).then(response => {
            if (response.status === 201) {
                const codAgenda = response.data.result[0].CodAgenda;
                const myItensAgenda = [];

                dataFesta.forEach(fullData => {
                    cartItems.forEach(item => {
                        if (item.type === 'combo') {
                            item.itens.forEach(combo => {
                                const newItem = new ItensAgenda(
                                    combo.COD_PRODUTO,
                                    combo.PRODUTO,
                                    combo.VLRUNIT,
                                    combo.VLRUNIT,
                                    codAgenda,
                                    fullData,
                                    user.CODIGO,
                                    combo.QUANTIDADE,
                                    agenda.AGECODAPP
                                );
        
                                myItensAgenda.push(newItem); 
                            });
                        }
    
                        if (item.type === 'product') {
                            const newItem = new ItensAgenda(
                                item.id,
                                item.name,
                                item.value,
                                (parseFloat(item.value) * parseFloat(item.amount)).toFixed(2).replace('.', ','),
                                codAgenda,
                                fullData,
                                user.CODIGO,
                                item.amount,
                                agenda.AGECODAPP
                            );
    
                            myItensAgenda.push(newItem);   
                        }
                    });
                });

                HttpClient.put('/InsereItensAgenda', myItensAgenda).then(response => {
                    if (response.status === 201) {
                        dispatch(LoadingAction.setLoading(false));
                        alertTrigger('fine', 'PRÉ-AGENDAMENTO REALIZADO COM SUCESSO', 'Entraremos em contato para confirmar o seu pedido.');
                        
                    }
                }, err => {
                    dispatch(LoadingAction.setLoading(false));
                    alertTrigger('error', 'ERRO AO REALIZAR O PRÉ-AGENDAMENTO', 'Ocoreu um erro inesperado.');
                });
            }
        }, err => {
            dispatch(LoadingAction.setLoading(false));
            alertTrigger('error', 'ERRO AO REALIZAR O PRÉ-AGENDAMENTO', 'Ocoreu um erro inesperado.');
        });
    };


    const redirectToHome = () => {
        const $body = document.querySelector('body');
        $body.classList.remove('modal-open');
        document.querySelectorAll('.modal-backdrop').forEach(item => item.remove());

        dispatch(ProductAction.cleanProducts());
        dispatch(CartAction.clearCart());
        dispatch(DateAction.clearDates());

        history.push("/");
    };


    const calculaFrete = () => {
        if (window.FRETE && validateAddress()) {
            calculaDistanciaFromCEP(`${userLogradouro}, ${userNumber || "1"} - ${userBairro}, ${userCity} - ${userState}`)
                .then(distanceInKm => {
                    if (distanceInKm > window.DISTANCE_WITHOUT_FEE) {
                        const calcFrete = (distanceInKm * 4) * window.FEE_PER_KM;
                        setFrete(calcFrete);
                    } else {
                        setFrete(0)
                    }
            });
        } else {
            setFrete(0)
        }
    };

    const validateAddress = () => {
        if (window.FRETE) {
            if (userLogradouro === "" || userBairro === "" || userCity === "" || userState === "") {
                alertTrigger('error', 'Endereço Incompleto', 'Por favor, preencha todos os campos do seu endereço corretamente', false);
                return false;
            }
    
            return true;
        } else {
            return true;
        }
    };

    const salveAddress = () => {
        calculaFrete();
        setNewAdress(false);
    };


    return (
        <div className="row h-100">
            <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7"  style={{maxHeight: '100vh', overflowY: 'auto' }}>
                <div className="card">
                    <div className="card-body">
                        <p className="fs-5">Qual é o endereço da festa?</p>
                        <div className="row">
                            <div className="col-12 my-3">
                                {newAddress ? (
                                    <div className="row">
                                        <div className="mb-3 col-12">
                                            <label forHtml="userName" className="form-label">Nome completo</label>
                                            <input type="text" value={userName}
                                                required
                                                onChange={(e) => setUserName(e.target.value)}
                                                className="form-control w-50 d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block"
                                                id="userName" aria-describedby="userName" />
                                            <input type="text" value={userName}
                                                required
                                                onChange={(e) => setUserName(e.target.value)}
                                                className="form-control d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none"
                                                id="userName" aria-describedby="userName" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label forHtml="userCel" className="form-label">Celular</label>
                                            <input type="text" value={userCel}
                                                required
                                                onChange={(e) => setUserCel(e.target.value)}
                                                className="form-control"
                                                data-inputmask="'mask':'(99)99999-9999'"
                                                id="userCel" />
                                        </div>
                                        <div className="mb-3 col-6">
                                            <label forHtml="userPhone" className="form-label">Telefone</label>
                                            <input type="text" value={userPhone}
                                                required
                                                onChange={(e) => setUserPhone(e.target.value)}
                                                className="form-control"
                                                data-inputmask="'mask':'(99)9999-9999'"
                                                id="userPhone" />
                                        </div>
                                        <div className="mb-3 col-12 d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block" style={{width: '30%'}}>
                                            <label forHtml="userCep" className="form-label">CEP (apenas números)</label>
                                            <input type="text"
                                                required
                                                max="9"
                                                maxLength="9"
                                                value={userCEP}
                                                onChange={(e) => setUserCEP(e.target.value)}
                                                onBlur={(e) => getCEP(e.target.value)}
                                                className="form-control"
                                                id="userCep" />
                                            {wrongCEP ? (
                                                <div className="text-danger">
                                                    CEP Incorreto!
                                                </div>): ('')}
                                        </div>
                                        <div className="mb-3 col-12 d-md-none d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none w-75">
                                            <label forHtml="userCep" className="form-label">CEP (apenas números)</label>
                                            <input type="text"
                                                required
                                                max="9"
                                                maxLength="9"
                                                value={userCEP}
                                                onChange={(e) => setUserCEP(e.target.value)}
                                                onBlur={(e) => getCEP(e.target.value)}
                                                className="form-control"
                                                id="userCep" />
                                            {wrongCEP ? (
                                                <div className="text-danger">
                                                    CEP Incorreto!
                                                </div>): ('')}
                                        </div>
                                        <div className="mb-3 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                                            <label forHtml="userAddress" className="form-label">Endereço</label>
                                            <input type="text"
                                                required
                                                value={userLogradouro}
                                                onChange={(e) => setUserLogradouro(e.target.value)}
                                                className="form-control"
                                                id="userAddress" />
                                        </div>
                                        <div className="mb-3 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2">
                                            <label forHtml="userNumber" className="form-label">Número</label>
                                            <input type="text"
                                                required
                                                value={userNumber}
                                                onChange={(e) => setUserNumber(e.target.value)}
                                                className="form-control d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block"
                                                id="userNumber" />
                                            
                                            <input type="text"
                                                required
                                                value={userNumber}
                                                onChange={(e) => setUserNumber(e.target.value)}
                                                className="form-control w-25 d-xl-block d-xl-none d-xxl-block d-xxl-none"
                                                id="userNumber" />
                                        </div>
                                        <div className="mb-3 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5">
                                            <label forHtml="userDistrict" className="form-label">Bairro</label>
                                            <input type="text"
                                                required
                                                value={userBairro}
                                                onChange={(e) => setUserBairro(e.target.value)}
                                                className="form-control"
                                                id="userDistrict" />
                                        </div>
                                        <div className="mb-3 col-sm-9 col-md-9 col-lg-5 col-xl-5 col-xxl-5">
                                            <label forHtml="userCity" className="form-label">Cidade</label>
                                            <input type="text" value={userCity}
                                                required
                                                onChange={(e) => setUserCity(e.target.value)}
                                                className="form-control"
                                                id="userCity" />
                                        </div>
                                        <div className="mb-3 col-2 d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
                                            <label forHtml="userState" className="form-label">Estado</label>
                                            <input type="text" value={userState}
                                                required
                                                onChange={(e) => setUserState(e.target.value)}
                                                className="form-control"
                                                id="userState" />
                                        </div>
                                        <div className="mb-3 col-3 d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none">
                                            <label forHtml="userState" className="form-label">Estado</label>
                                            <input type="text" value={userState}
                                                required
                                                onChange={(e) => setUserState(e.target.value)}
                                                className="form-control"
                                                id="userState" />
                                        </div>
                                        <div className="col-12 my-auto text-end d-lg-block d-sm-none d-md-block d-none d-sm-block">
                                            <button type="button" class="btn btn-success w-25"
                                                onClick={() => salveAddress()}>Salvar</button>
                                        </div>
                                        <div className="col-12 d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none">
                                            <button type="button" class="btn btn-success w-100"
                                                onClick={() => salveAddress()}>Salvar</button>
                                        </div>
                                    </div>
                                ): (
                                    <div className="row border rounded">
                                        <div className="col-sm-12 col-md-12 col-lg-8 col-xxl-8 col-xxl-8">
                                            <p className="fs-6 fw-bold m-0">{userNumber ? `${userLogradouro}, ${userNumber}`: userLogradouro}</p>
                                            <p className="m-0 fw-lighter">
                                                <span>{userCity}, </span>
                                                <span>{userState} - CEP {userCEP}</span>
                                            </p>
                                            <p className="m-0 fw-lighter">
                                                <span>{userName} - </span><span>{userCel}</span>
                                            </p>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 my-auto">
                                            <button type="button" class="btn btn-link" onClick={() => setNewAdress(true)}>Editar endereço</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-12 my-3">
                                <p className="fs-5 m-0">Sobre a festa</p>
                                <p  style={{fontSize: '14px'}}>(*) Compo obrigatório</p>
                                <div className="row ">
                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                                        <label forHtml="time-start">Início da festa *</label><br/>
                                        <input type="time" id="time-start" className="w-50"
                                            value={horaInicial} onChange={(e) => setHoraInicialFinal(e.target.value)} required></input>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                                        <label forHtml="time-end">Retirada dos brinquedos *</label><br/>
                                        <input type="time" id="time-end" className="w-50" disabled value={horaFinal} required></input>
                                    </div>

                                    <div className="col-12 mb-3">
                                        <label forHtml="aniversariante" className="form-label">Nome do(a) Aniversariante/Evento *</label>
                                        <input className="form-control w-50" value={eventName} onChange={(e) => setEventName(e.target.value)}
                                            placeholder="Nome do(a) aniversariante/evento" id="aniversariante" type="text" required />
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                                        <label forHtml="dataNascimento" className="form-label">Data de Nascimento *</label>
                                        <input className="form-control" onChange={(e) => setIdade(e.target.value)} id="dataNascimento" type="date" />
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                                        <label forHtml="grau" className="form-label">Grau de Parentesco *</label>
                                        <select id="grau" value={grauParent} onChange={(e) => setGrauParent(e.target.value)} className="form-select">
                                            <option value='' selected disabled>Selecione uma opção</option>
                                            {GRAU.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                                <label forHtml="area">Área dos brinquedos</label>
                                <select id="area" value={area} onChange={(e) => setArea(e.target.value)}
                                    className="form-select" aria-label="Select area">
                                    <option selected value='' disabled>Escolha a área dos brinquedos</option>
                                    {AREAS.map((item, index) => (
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                                <label forHtml="voltagem">Voltagem</label>
                                <select id="voltagem" value={voltagem} onChange={(e) => setVoltagem(e.target.value)}
                                    className="form-select" aria-label="Select voltagem">
                                    <option selected value='' disabled>Escolha uma voltagem</option>
                                    {VOLTAGENS.map((item, index) => (
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-12 mb-3">
                                <label forHtml="floatingTextarea">Observações</label>
                                <textarea className="form-control" value={observacao} onChange={(e) => setObservacao(e.target.value)}
                                    placeholder="Observação" id="floatingTextarea"></textarea>
                            </div>
                        </div>
                        <div className="row d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none mb-4">
                                            <div className="col-12">
                                                <h5 className="card-title">Você vai reservar:</h5>
                                                <ul className="list-group">
                                                    { cartItems.map((item, index) => (
                                                        <li key={index} className="list-group-item">
                                                            <span>{item.amount} {item.name}</span><br/>
                                                            <span>R$ {convertToReal(item.value,  item.amount)}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        

                                        <div className="row d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none mb-4">
                                            <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                                                <label forHtml="pagamento">Forma de Pagamento *</label>
                                                <select id="pagamento" value={formaPagamento} onChange={(e) => setFormaPagamento(e.target.value)}
                                                    className="form-select" aria-label="Select pagamento">
                                                    <option selected value='' disabled>Escolha uma forma de pagamento</option>
                                                    {pagamentos.map(item => (
                                                        <option key={item.CODIGO} value={item.CODIGO}>
                                                            {item.DESCRICAO} - Taxa de {item.ACRESCIMO}%
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>


                                        {formaPagamento !== ''? (
                                            <div className="mt-5 mb-5">
                                                { parseFloat(getAcrescimo()) > 0 ? (
                                                    <div>
                                                        { window.FRETE ? (
                                                            <div>
                                                                <div>
                                                                    <span >Frete: </span><span>{frete == 0 ? 'Grátis' : `R$ ${frete.toFixed(2).replace('.',',')}`}</span>
                                                                </div>
                                                            </div>
                                                        ) : ''}
                                                        <span>Total + Taxa de {getAcrescimo()}%:</span><br/>
                                                        <span className="text-success">R$ {getValoraPagar()}</span>
                                                    </div>
                                                ): (
                                                    <div>
                                                        { window.FRETE ? (
                                                            <div>
                                                                <span >Frete: </span><span>{frete == 0 ? 'Grátis' : `R$ ${frete.toFixed(2).replace('.',',')}`}</span>
                                                            </div>
                                                        ): ''}
                                                        <span>Total:</span><br/>
                                                        <span className="text-success">R$ {getValoraPagar()}</span>
                                                    </div>
                                                )}
                                            </div>
                                        ): (
                                            <div className="mt-5 mb-5">
                                                { window.FRETE ? (
                                                    <div>
                                                        <span >Frete: </span><span>{frete == 0 ? 'Grátis' : `R$ ${frete.toFixed(2).replace('.',',')}`}</span>
                                                    </div> ): ''}
                                                <span>Total:</span><br/>
                                                <span className="text-success">R$ {getValoraPagar()}</span>
                                            </div>
                                        )}

                                        <div className="text-start mt-5 mb-0">
                                            <button type="button"
                                                disabled={canContinue === true ? false: true }
                                                className={canContinue === true ? "btn btn-primary": "btn btn-secondary" } onClick={() => createAgenda()}>Concluir Pré-agendamento</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5 d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Você vai reservar:</h5>
                        <ul className="list-group">
                            { cartItems.map((item, index) => (
                                <li key={index} className="list-group-item d-flex justify-content-between">
                                    <span>{item.amount} {item.name}</span>
                                    <span style={{marginRight: '14px', fontSize: '14px'}}>R$ {convertToReal(item.value,  item.amount)}</span>
                                </li>
                            ))}
                        </ul>

                        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 my-3">
                            <label forHtml="pagamento">Forma de Pagamento *</label>
                            <select id="pagamento" value={formaPagamento} onChange={(e) => setFormaPagamento(e.target.value)}
                                className="form-select" aria-label="Select pagamento">
                                <option selected value='' disabled>Escolha uma forma de pagamento</option>
                                {pagamentos.map(item => (
                                    <option key={item.CODIGO} value={item.CODIGO}>
                                        {item.DESCRICAO} - Taxa de {item.ACRESCIMO}%
                                    </option>
                                ))}
                            </select>
                        </div>

                        {formaPagamento !== ''? (
                            <div className="mt-5 mb-5">
                                { parseFloat(getAcrescimo()) > 0 ? (
                                    <div>
                                        { window.FRETE ? (
                                            <div>
                                                <span >Frete: </span><span>{frete == 0 ? 'Grátis' : `R$ ${frete.toFixed(2).replace('.',',')}`}</span>
                                            </div>
                                        ): ''}
                                        <span>Total + Taxa de {getAcrescimo()}%: </span><span className="text-success">R$ {getValoraPagar()}</span>
                                    </div>
                                ): (
                                    <div>
                                        { window.FRETE ? (
                                            <div>
                                                <span >Frete: </span><span>{frete == 0 ? 'Grátis' : `R$ ${frete.toFixed(2).replace('.',',')}`}</span>
                                            </div>
                                        ) : ''}
                                        <span className="mr-2">Total:</span><br/>
                                        <span className="text-success">R$ {getValoraPagar()}</span>
                                    </div>
                                )}
                            </div>
                        ): (
                            <div className="mt-5 mb-5">
                                { window.FRETE ? (
                                    <div>
                                        <span >Frete: </span><span>{frete == 0 ? 'Grátis' : `R$ ${frete.toFixed(2).replace('.',',')}`}</span>
                                    </div>
                                ) : ''}
                                <span>Total: </span><span className="text-success">R$ {getValoraPagar()}</span>
                            </div>
                        )}


                        {/* <div className="text-start mt-5 mb-0 d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none">
                            <button type="button"
                                disabled={canContinue === true ? false: true }
                                className="btn btn-primary w-50" onClick={() => createAgenda()}>Concluir Pré-agendamento</button>
                        </div> */}
                        <div className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
                            <button type="button"
                                disabled={canContinue === true ? false: true }
                                className={canContinue === true ? "btn btn-primary": "btn btn-secondary" }
                                onClick={() => createAgenda()}>Concluir pré-agendamento</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="registerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Alerta</h5>
                            <button type="button" class="btn-close" onClick={registerModalInfos.redirect ? () => redirectToHome(): null} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            {registerModalInfos.status === 'fine' ? (
                                <div class="alert alert-success" role="alert">
                                    <h4 class="alert-heading">{registerModalInfos.title}</h4>
                                    <p>{registerModalInfos.msg}</p>
                                </div>
                            ) : (
                                <div class="alert alert-warning" role="alert">
                                    <h4 class="alert-heading">{registerModalInfos.title}</h4>
                                    <p>{registerModalInfos.msg}</p>
                                </div>
                            )}
                        </div>
                        <div class="modal-footer">
                            <button className="btn btn-light"  data-bs-dismiss="modal" onClick={registerModalInfos.redirect ? () => redirectToHome(): null}>Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
            
            {loading === true ? (
                <div className="loading-screen text-center">
                    <img 
                    width="50"
                    src={window.BASE_HOME + '/img/loading.gif'}
                    alt="loading" />
                    <p className="fs-2 text-light">Carregando</p>
                </div>
            ): ('')}
        </div>
    );
}


export default connect(state => ({
    user: state.user.user,
    cartItems: state.cart.cartItems,
    dataFesta: state.dataFesta.date,
    loading: state.loading.loading 
}))(Schedule);
