import * as Moment from 'moment';

export class User {

    constructor(razaoSocial, end, bairro, cep, city, estado, cpfCnpj, phone, cell, email, pass, enderecoNumber) {
        this.RAZAO_SOCIAL = razaoSocial.toLocaleUpperCase();
        this.ENDERECO = `${end}, ${enderecoNumber}`.toLocaleUpperCase();
        this.BAIRRO = bairro.toLocaleUpperCase();
        this.CEP = cep.toLocaleUpperCase();
        this.CIDADE = city.toLocaleUpperCase();
        this.ESTADO = estado.toLocaleUpperCase();
        this.CPF_CNPJ = cpfCnpj.toLocaleUpperCase();

        const underscorePhone = phone.search('_');
        if (underscorePhone !== -1) {
            phone = phone.slice(0, -1);
        }

        this.FONE = phone.toLocaleUpperCase();
        this.CELULAR = cell.toLocaleUpperCase();
        this.EMAIL = email.toLocaleUpperCase();
        this.CONTATO = '';
        this.OBS = '';
        this.SENHAWEB = pass;
        this.CANAL = 'LOJA VIRTUAL';

        this.CLICODAPP = `${Moment().format('YYYYMMDD')}${Moment().format('HHmmss')}`;
        this.DATA_CADASTRO = Moment().format('DD/MM/YYYY');
    }
}
