import React, { useState } from 'react';
import HttpClient from '../../../services/HttpClient';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as UserAction from '../../../store/actions/user';
import * as LoadingAction from '../../../store/actions/loading';

function Login({ loading, dispatch }) {
    const [userid, setUserId] = useState('');
    const [forgotPass, setForgotPass] = useState(false);
    const [userPass, setUserPass] = useState('');
    const [wrongPass, setWrongPass] = useState(false);
    const [wrongUser, setWrongUser] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [loginModalInfos, setLoginModalInfos] = useState({});
    const history = useHistory();

    const submitLogin = (user , pass) => {
        
        if (user && pass) {
            dispatch(LoadingAction.setLoading(true));
            const login = [{
                ID: user,
                KEY: pass
            }];

            HttpClient.post('GetClientesTxt', login).then(res => {
                dispatch(LoadingAction.setLoading(false));
                const response = res.data.result[0][0];

                if (res.status === 200) {
                    dispatch(UserAction.setUser(response));
                    history.push("/confirm");
                }
            }, err => {
                dispatch(LoadingAction.setLoading(false));
                switch(err.request.response) {
                    case 'senha incorreta':
                        setWrongPass(true);
                        break;
                     case 'cadastro inexistente':
                        setWrongUser(true);
                        break;
                    case 'usuario inativo':
                        alert('Você deve confirmar seu email antes de finalizar o pedido.');
                        break;
                    default:
                        break;
                }
            });
        }
    }

    const validatePass = () => {
        const buttonElem = document.createElement('button');
        buttonElem.type = 'button';
        buttonElem.setAttribute('data-bs-toggle', 'modal');
        buttonElem.setAttribute('data-bs-target', '#loginModal');
        buttonElem.style.display = 'none';
        
        dispatch(LoadingAction.setLoading(true));
        HttpClient.get(`GetPassword/${userid}`).then(res => {
            document.body.appendChild(buttonElem);
            setLoginModalInfos({
                status: 'fine',
                title: 'Validado com sucesso!',
                msg: res.data.charAt(0).toUpperCase() + res.data.slice(1),
            });

            dispatch(LoadingAction.setLoading(false));
            buttonElem.click();
            buttonElem.remove();
        }, err => {
            dispatch(LoadingAction.setLoading(false));
            document.body.appendChild(buttonElem);
            switch(err.request.response) {
                case 'cadastro inexistente':
                    setLoginModalInfos({
                        status: 'warning',
                        title: 'Cadastro Inexistente',
                        msg: 'Não encontramos nenhum cadastro com o CPF/CNPJ informado.\nPor favor, clique em "Criar Conta" para fazer o cadastro no sistema.',
                    });

                    buttonElem.click();
                    buttonElem.remove();
                    break;
                 case 'email inexistente':
                    setLoginModalInfos({
                        status: 'warning',
                        title: 'Email Inexistente',
                        msg: 'Não encontramnos nenhum cadastro registrado. Por favor, entre em contato com a empresa para obter mais informações.',
                    });

                    buttonElem.click();
                    buttonElem.remove();
                    break;
                default:
                    break;
            }
        })
    };


    return (
        <div className="w-100">
            {forgotPass ? (
                <div>
                    <div className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
                        <div className="card w-50 mx-auto my-5">
                            <div className="card-body row">
                                <div className="col-12">
                                    <h1>Recuperar Senha</h1>
                                    <p>Enviaremos uma nova senha para o email cadastrado no sistema.</p>
                                </div>
                                <div className="col-12 mb-4">
                                    <label htmlFor="userid" className="form-label">Digite seu CPF/CNPJ</label>
                                    <input type="number" className="form-control"
                                        value={userid}
                                        required
                                        onChange={(e) => {
                                            setUserId(e.target.value);
                                            setWrongUser(false);
                                        }}
                                        placeholder="CPF ou CNPJ"
                                        autoComplete="off"
                                        id="userid"
                                        aria-describedby="userid" />
                                </div>
                                <div className="col-12 mb-5">
                                    <button 
                                        disabled={!(userid)}
                                        className="btn btn-dark"
                                        onClick={() => validatePass()}>Validar cadastro</button>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-light" onClick={() => setForgotPass(false)}>Voltar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none">
                        <div className="card w-100">
                            <div className="card-body row">
                                <div className="col-12">
                                    <h1>Recuperar Senha</h1>
                                    <p>Enviaremos uma nova senha para o email cadastrado no sistema.</p>
                                </div>
                                <div className="col-12 mb-4">
                                    <label htmlFor="userid" className="form-label">Digite seu CPF/CNPJ</label>
                                    <input type="number" className="form-control"
                                        value={userid}
                                        required
                                        onChange={(e) => {
                                            setUserId(e.target.value);
                                            setWrongUser(false);
                                        }}
                                        placeholder="CPF ou CNPJ"
                                        autoComplete="off"
                                        id="userid"
                                        aria-describedby="userid" />
                                </div>
                                <div className="col-12 mb-5">
                                    <button 
                                        disabled={!(userid)}
                                        className="btn btn-dark"
                                        onClick={() => validatePass()}>Validar cadastro</button>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-light" onClick={() => setForgotPass(false)}>Voltar</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Alerta</h5>
                                    <button type="button" class="btn-close" onClick={() => setForgotPass(false)} data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    {loginModalInfos.status === 'fine' ? (
                                        <div class="alert alert-success" role="alert">
                                            <h4 class="alert-heading">{loginModalInfos.title}</h4>
                                            <p>{loginModalInfos.msg}</p>
                                      </div>
                                    ) : (
                                        <div class="alert alert-warning" role="alert">
                                            <h4 class="alert-heading">{loginModalInfos.title}</h4>
                                            <p>{loginModalInfos.msg}</p>
                                        </div>
                                    )}
                                </div>
                                <div class="modal-footer">
                                    <button className="btn btn-light"  data-bs-dismiss="modal" onClick={() => setForgotPass(false)}>Voltar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading === true ? (
                        <div className="loading-screen text-center">
                            <img 
                            width="50"
                            src={window.BASE_HOME  + '/img/loading.gif'}
                            alt="loading" />
                            <p className="fs-2 text-light">Carregando</p>
                        </div>
                    ): ('')}
                </div>
            ) : (
                <div>
                    <section className="d-lg-flex justify-content-lg-center d-xl-flex justify-content-xl-center 
                        d-xxl-flex justify-content-xxl-center align-self-center d-md-none
                        d-lg-block d-sm-none d-md-block
                        d-none d-sm-block align-self-lg-center align-self-xl-center align-self-xxl-center mt-lg-5 mt-xl-5 mt-xxl-5">
                        <div className="card text-center w-25">
                            <div className="card-body">
                                <h1 className="mb-5">Login</h1>
                                <div className="mb-4 mt-3 text-start">
                                    <label htmlFor="userid" className="form-label">CPF ou CNPJ</label>
                                    <input type="number" className="form-control"
                                        value={userid}
                                        required
                                        onChange={(e) => {
                                            setUserId(e.target.value);
                                            setWrongUser(false);
                                        }}
                                        placeholder="CPF ou CNPJ"
                                        autoComplete="off"
                                        id="userid"
                                        aria-describedby="userid" />
                                    {wrongUser ? (
                                        <div className="text-danger">
                                            Usuário Inexistente
                                        </div>): ('')}
                                </div>
                                <div className="mb-2 text-start">
                                    <label htmlFor="pass" className="form-label">Senha</label>
                                    <div className="input-group">
                                    <input type={showPass ? 'text': 'password'}
                                        value={userPass}
                                        required
                                        onChange={(e) => {
                                            setUserPass(e.target.value);
                                            setWrongPass(false);
                                        }}
                                        placeholder="Senha"
                                        autoComplete="off"
                                        className="form-control " aria-describedby="basic-addon2"
                                        id="pass" />
                                    <button class="input-group-text" id="basic-addon2" onClick={() => setShowPass(!showPass)}>
                                        {showPass ? (<i class="bi bi-eye-slash-fill"></i>) : (<i class="bi bi-eye-fill"></i>)}
                                    </button>

                                    </div>
                                    {wrongPass ? (
                                        <div className="text-danger">
                                            Senha incorreta
                                        </div>): ('')}
                                </div>
                                <div className="mb-3 text-end">
                                    <button className="btn btn-link" onClick={() => setForgotPass(true)}>Esqueceu sua senha?</button>
                                </div>
                                <button type="button"
                                    className="btn btn-dark mt-4 mb-5 w-100"
                                    disabled={!(userid && userPass)}
                                    onClick={() => submitLogin(userid, userPass)}>Continuar</button>
                                
                                <Link to="/register" className="btn btn-light mt2 w-50">Criar Conta</Link>
                            </div>
                        </div>

                        {loading === true ? (
                            <div className="loading-screen text-center">
                                <img 
                                width="50"
                                src={window.BASE_HOME  + '/img/loading.gif'}
                                alt="loading" />
                                <p className="fs-2 text-light">Carregando</p>
                            </div>
                        ): ('')}
                </section>
                <section className="d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none">
                    <div className="card text-center">
                        <div className="card-body">
                            <h1 className="mb-5">Login</h1>
                            <div className="mb-4 mt-3 text-start">
                                <label htmlFor="userid" className="form-label">CPF ou CNPJ</label>
                                <input type="number" className="form-control"
                                    value={userid}
                                    required
                                    onChange={(e) => {
                                        setUserId(e.target.value);
                                        setWrongUser(false);
                                    }}
                                    placeholder="CPF ou CNPJ"
                                    autoComplete="off"
                                    id="userid"
                                    aria-describedby="userid" />
                                {wrongUser ? (
                                    <div className="text-danger">
                                        Usuário Inexistente
                                    </div>): ('')}
                            </div>
                            <div className="mb-4 text-start">
                                <label htmlFor="pass" className="form-label">Senha</label>
                                <div className="input-group">
                                    <input type={showPass ? 'text': 'password'}
                                        value={userPass}
                                        required
                                        onChange={(e) => {
                                            setUserPass(e.target.value);
                                            setWrongPass(false);
                                        }}
                                        placeholder="Senha"
                                        autoComplete="off"
                                        className="form-control"
                                        aria-describedby="basic-addon2"
                                        id="pass" />
                                    <button class="input-group-text" id="basic-addon2" onClick={() => setShowPass(!showPass)}>
                                        {showPass ? (<i class="bi bi-eye-slash-fill"></i>) : (<i class="bi bi-eye-fill"></i>)}
                                    </button>
                                </div>
                                {wrongPass ? (
                                    <div className="text-danger">
                                        Senha incorreta
                                    </div>): ('')}
                            </div>
                            <div className="mb-3 text-end">
                                    <button className="btn btn-link" onClick={() => setForgotPass(true)}>Esqueceu sua senha?</button>
                            </div>

                            <button type="button"
                                className="btn btn-dark mt-4 mb-5 w-100"
                                disabled={!(userid && userPass)}
                                onClick={() => submitLogin(userid, userPass)}>Continuar</button>
                            
                            <Link to="/register" className="btn btn-light mt2 w-50">Criar Conta</Link>
                        </div>
                    </div>

                    {loading === true ? (
                        <div className="loading-screen text-center">
                            <img 
                            width="50"
                            src={window.BASE_HOME  + '/img/loading.gif'}
                            alt="loading" />
                            <p className="fs-2 text-light">Carregando</p>
                        </div>
                    ): ('')}
                </section>
                    </div>
            )}
        </div>
    );
}


export default connect(state => ({
    user: state.user,
    loading: state.loading.loading
}))(Login);