export function setCartItem(product) {
    return {
        type: "SET_CART_ITEM",
        product
    }
}


export function decreaseAmountValue(product) {
    return {
        type: "DECREASE_AMOUNT_VALUE",
        product
    }
}


export function increaseAmountValue(product, maxAvailable) {
    return {
        type: "INCREASE_AMOUNT_VALUE",
        product,
        maxAvailable
    }
}


export function removeCartitem(product) {
    return {
        type: "REMOVE_CART_ITEM",
        product
    }
}


export function clearCart() {
    return {
        type: "CLEAR_CART"
    }
}