export function setProducts(products) {
    return {
        type: 'SET_PRODUCTS',
        products
    }
}


export function cleanProducts() {
    return {
        type: 'CLEAN_PRODUCTS'
    }
}

export function cleanCombos() {
    return {
        type: 'CLEAN_COMBOS'
    }
}


export function setCombos(combos) {
    return {
        type: 'SET_COMBOS',
        combos
    }
}

export function selectProductsList(item) {
    return {
        type: 'SELECT_PRODUCT_LIST',
        selected: item
    }
}