
const INITIAL_STATE = {
    date: []
};


export default function reducer(state=INITIAL_STATE, action) {

    if (action.type === 'SET_UNIC_DATE') {
        return {...state, date: action.date }
    }

    if (action.type === 'SET_DATE_RANGE') {
        return {...state, date: action.date }
    }

    if (action.type === 'CLEAR_DATE') {
        return {...state, date: []}
    }

    return state;
}
