import * as Moment from 'moment';


export class Agenda {

    constructor(cliente, diaFesta, horaInicial, horaRetorno, endereco, endNumber=null,
    cidade, bairro, uf, taxa, vlrDesconto, total,
    formaPagamento, dataRetorno, voltagem, area, cep, aniversariante, grau, idade, observacao, transporte) {
        this.FILIAL = "1";
        this.AGECODAPP = `${Moment().format('YYYYMMDD')}${Moment().format('HHmmss')}`;
        this.CLIENTE = cliente;
        this.DATA_AGENDA = diaFesta;
        this.HORA = horaInicial;
        if (endNumber) {
            this.END_FESTA = `${endereco}, ${endNumber}`.toLocaleUpperCase();
        } else {
            this.END_FESTA = endereco.toLocaleUpperCase();
        }
        this.CIDADE_FESTA = cidade.toLocaleUpperCase();
        this.BAIRRO_FESTA = bairro.toLocaleUpperCase();
        this.EST_FESTA = uf.toLocaleUpperCase();
        this.OBSERVACAO = observacao.toLocaleUpperCase();
        this.TRANSPORTE = transporte;
        this.STATUS = 'R';
        this.VLR_TAXA = taxa;
        this.VLR_DESCONTO = vlrDesconto;
        this.TOTAL = `${total}`.replace('.', ',');
        this.FORMA_PGTO = formaPagamento.toLocaleUpperCase();
        this.ANIVERSARIANTE = aniversariante.toLocaleUpperCase();
        this.GRAU = grau.toLocaleUpperCase();
        const oldYearDate = new Date(idade).getFullYear();
        const currentDate = new Date().getFullYear();
        this.IDADE = idade > 0 ? currentDate - oldYearDate : 0;
        this.USUARIO = 'LOJA VIRTUAL';
        this.DATA_CADASTRO = Moment().format('DD/MM/YYYY');
        this.DATA_RETORNO = dataRetorno;
        this.HORA_RET = horaRetorno;
        this.VOLTAGEM = voltagem;
        this.AREA = area;
        this.CEP_AGENDA = cep;
        this.CANAL = 'LOJA VIRTUAL';
    }
}