import React from 'react';
import { Link } from 'react-router-dom';


function Authentication() {
    document.querySelector('.modal-backdrop')?.remove();
    const $body = document.querySelector('body');
    console.log('Body => ', $body);

    $body.classList.remove('modal-open');

    return (
        <div className="w-100">
            <section className="d-lg-flex justify-content-lg-center d-xl-flex justify-content-xl-center d-xxl-flex justify-content-xxl-center align-self-center d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block" style={{marginTop: '10%'}}>
                <div className="card text-center w-25">
                    <div className="card-body">
                        <h5 className="card-title mb-5">Olá, para alugar os produtos, acesse sua conta</h5>
                        <Link to="/register" className="btn btn-dark mb-4 w-50">Sou novo</Link>
                        <br/>
                        <Link to="/login" className="btn btn-light mt2 w-50">Já sou cliente</Link>
                    </div>
                </div>
            </section>
            <section className="d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none">
                <div className="card border-0 text-center">
                    <div className="card-body">
                        <h5 className="card-title mb-5">Olá, para alugar os produtos, acesse sua conta</h5>
                        <Link to="/register" className="btn btn-dark mb-4 w-50">Sou novo</Link>
                        <br/>
                        <Link to="/login" className="btn btn-light mt2 w-50">Já sou cliente</Link>
                    </div>
                </div>
            </section>
        </div>
    );
}



export default Authentication;
